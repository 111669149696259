import {
  Card,
  CardHeader,
  Container,
  Button,
  Box,
  Grid,
  Typography,
  CardContent,
  FormLabel,
  CircularProgress,
  Stack,
} from '@mui/material';
import { Edit, Visibility } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';

import { Link as RouterLink, useParams } from 'react-router-dom';
import API from '../../common/services/API';
import CardContentWithLoader from '../../common/components/CardContentWithLoader';

export default function UserDetails() {
  const { id } = useParams();
  const [details, setDetails] = useState([]);
  const [walkthroughs, setWalkthroughs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => fetchData(), []);

  const fetchData = async () => {
    setLoading(true);
    const userResponse = await API.get(`/users/${id}`);

    setDetails(userResponse.data);

    const userWalkthroughs = await API.get(`/users/${id}/walkthroughs`);
    setWalkthroughs(userWalkthroughs.data);
    setLoading(false);
  };

  function NoRowsOverlay() {
    return (
      <Stack
        top="60%"
        alignItems="center"
        position="relative"
        justifyContent="center"
      >
        You have no assigned walkthroughs.
      </Stack>
    );
  }

  const columns = [
    {
      field: 'actions',
      headerName: 'Actions',
      headerClassName: 'secondary-color-header',
      type: 'actions',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 85,
      flex: 0.4,
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={<Visibility />}
          component={RouterLink}
          to={`/walkthroughs/${row.id}`}
        />,
      ],
    },
    {
      field: 'Site',
      headerClassName: 'secondary-color-header',
      headerAlign: 'left',
      headerName: 'Site Name',
      minWidth: 100,
      flex: 6,
      valueGetter: (params) => params.row.Site.name,
    },
    {
      field: 'name',
      headerClassName: 'secondary-color-header',
      headerAlign: 'left',
      headerName: 'Name',
      minWidth: 100,
      disableColumnMenu: true,
      flex: 6,
      valueGetter: (params) => params.row.name || '-',
    },
    {
      field: 'status',
      headerClassName: 'secondary-color-header',
      headerAlign: 'left',
      headerName: 'Status',
      minWidth: 100,
      disableColumnMenu: true,
      flex: 6,
      valueGetter: (params) => params.row.status || '-',
    },
    {
      field: 'progress',
      headerClassName: 'secondary-color-header',
      headerName: 'Progress',
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        let string = params.row.progress ? `${params.row.progress}%` : '-';

        if (
          params.row.progress &&
          params.row.assetsCount !== null &&
          params.row.completedAssetsCount !== null
        ) {
          string += ` (${params.row.completedAssetsCount}/${params.row.assetsCount})`;
        }

        return string;
      },
    },
    {
      field: 'due_date',
      headerClassName: 'secondary-color-header',
      type: 'date',
      headerAlign: 'left',
      headerName: 'Due Date',
      minWidth: 100,
      flex: 6,
      valueFormatter: (params) => {
        if (params.value === null) {
          return;
        }
        return new Date(params.value).toLocaleDateString();
      },
    },
  ];
  console.log(walkthroughs);
  return (
    <Container maxWidth="xl">
      <Card>
        <CardHeader
          title={details.name}
          sx={{ backgroundColor: '#275295', color: '#FFF' }}
          action={
            <>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Button
                variant="contained"
                startIcon={<Edit />}
                sx={{ backgroundColor: '#519801' }}
                component={RouterLink}
                to={`/users/${id}/edit`}
              >
                EDIT USER
              </Button>
            </>
          }
        ></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card sx={{ backgroundColor: '#F0F1F9' }}>
                <CardHeader
                  title="User Details"
                  sx={{ backgroundColor: '#6E7B82', color: '#FFF' }}
                ></CardHeader>
                <CardContentWithLoader loading={loading}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormLabel>Name</FormLabel>

                      <Typography component="div">
                        {details.name ?? '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormLabel>Email</FormLabel>

                      <Typography component="div">
                        {details.email ?? '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormLabel>Admin Status</FormLabel>
                      <Typography component="div">
                        {details.admin === true ? 'Yes' : 'No'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormLabel>Title</FormLabel>

                      <Typography component="div">
                        {details.title ?? '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContentWithLoader>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ backgroundColor: '#F0F1F9' }}>
                <CardHeader
                  title="Assigned Walkthroughs"
                  sx={{ backgroundColor: '#6E7B82', color: '#FFF' }}
                ></CardHeader>
                <Grid container>
                  <DataGrid
                    sx={{
                      '& .secondary-color-header': {
                        backgroundColor: '#C4CBD6',
                      },
                    }}
                    disableSelectionOnClick
                    loading={loading}
                    autoHeight
                    rows={walkthroughs}
                    columns={columns}
                    components={{ NoRowsOverlay }}
                  />
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}
