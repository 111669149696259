import { useState, useEffect } from 'react';
import { nullifyEmptyString } from '../../utilities';
import {
  Card,
  CardHeader,
  CardContent,
  Container,
  CardActions,
  Button,
  FormControl,
  TextField,
  Grid,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  IconButton,
  Chip,
} from '@mui/material';

import API from '../../common/services/API';

import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
} from '@mui/icons-material';

import { Link as RouterLink } from 'react-router-dom';

import { useParams, useNavigate } from 'react-router-dom';

export default function UpdateSite() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formErrors, setFormErrors] = useState({});
  const [customFormErrors, setCustomFormErrors] = useState({});
  const [customFields, setCustomFields] = useState([]);

  const fieldTypes = [
    { value: 'text', label: 'Text' },
    { value: 'boolean', label: 'Checkbox' },
    { value: 'dropdown', label: 'Dropdown' },
    { value: 'textarea', label: 'Long Text' },
    { value: 'date', label: 'Date' },
    { value: 'datetime', label: 'Date and Time' },
    { value: 'number', label: 'Number' },
  ];

  const requiredFields = {
    name: true,
  };

  const [details, setDetails] = useState({
    name: null,
    description: null,
    contact_name: null,
    contact_email: null,
    contact_phone: null,
  });

  const fetchData = async () => {
    const response = await API.get(`/sites/${id}`);
    const fields = response.data.Fields.map((field) => {
      const type = fieldTypes.find((f) => f.value === field.type);
      if (type) {
        field.SelectedType = type;
      }

      return field;
    });
    setCustomFields(response.data.Fields);
    setDetails(response.data);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setDetails({ ...details, [name]: value });
  };

  const validate = () => {
    const errors = {};
    for (const [field, required] of Object.entries(requiredFields)) {
      if (required && !details[field]) {
        errors[field] = 'this field is required.';
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return false;
    }

    setFormErrors({});
    return true;
  };

  useEffect(() => fetchData(), []);

  const submit = async () => {
    if (!validate()) {
      return;
    }
    try {
      const stateToSend = {
        ...nullifyEmptyString(details),
        CustomFields: customFields,
      };
      const response = await API.put(`/sites/${id}`, stateToSend);

      // const userid = response.data.id; i dont think  i need this for anything?
      navigate(`/sites/${id}`);
    } catch (err) {
      alert('something went wrong');
      console.error(err);
    }
  };

  const handleCustomFieldChange = (event, index) => {
    const { name, value } = event.target;
    const fields = [...customFields];
    fields[index] = { ...fields[index], [name]: value };
    setCustomFields(fields);
  };

  const handleCustomFieldTypeChange = (value, index) => {
    const fields = [...customFields];
    fields[index] = {
      ...fields[index],
      SelectedType: value,
      type: value.value,
    };
    setCustomFields(fields);
  };

  const toggleCustomFieldCheckbox = (name, index) => {
    const fields = [...customFields];
    fields[index] = { ...fields[index], [name]: !fields?.[index]?.[name] };
    setCustomFields(fields);
  };

  const moveFieldUp = (index) => {
    // swap the order for the current field with the field before it
    const newFields = [...customFields];
    const beforeIndex = index - 1;
    const beforeField = newFields[beforeIndex];
    const currentField = newFields[index];

    if (!(currentField && beforeField)) {
      return;
    }

    newFields[beforeIndex] = { ...beforeField, order: currentField.order };
    newFields[index] = { ...currentField, order: beforeField.order };
    setCustomFields(newFields);
  };

  const moveFieldDown = (index) => {
    // swap the order for the current field with the field after it
    const newFields = [...customFields];
    const afterIndex = index + 1;
    const afterField = newFields[afterIndex];
    const currentField = newFields[index];
    console.log(afterField);

    if (!(currentField && afterField)) {
      return;
    }

    newFields[afterIndex] = { ...afterField, order: currentField.order };
    newFields[index] = { ...currentField, order: afterField.order };
    setCustomFields(newFields);
  };

  const moveFieldToTop = (index) => {
    // set the current field to 1, and add 1 to the order for all fields before this field
    // subtract 1 to the order for all the fields after this field
    const newFields = [...customFields];
    const currentField = newFields[index];
    newFields[index] = { ...currentField, order: 1 };
    for (let x = index; x >= 0; x--) {
      newFields[x] = { ...newFields[x], order: newFields[x].order + 1 };
    }

    for (let x = index; x < newFields.length; x++) {
      newFields[x] = { ...newFields[x], order: newFields[x].order - 1 };
    }
    console.log('new fields', newFields);
    setCustomFields(newFields);
  };

  const moveFieldToBottom = (index) => {
    // set the current field to the max length,
    // subtract 1 to the order for all the fields after this field
    const newFields = [...customFields];
    const currentField = newFields[index];
    newFields[index] = { ...currentField, order: newFields.length + 1 };

    for (let x = index; x < newFields.length; x++) {
      newFields[x] = { ...newFields[x], order: newFields[x].order - 1 };
    }
    console.log('new fields', newFields);
    setCustomFields(newFields);
  };

  const deleteField = (index) => {
    const fields = [...customFields];
    fields[index] = { ...fields[index], deletedAt: new Date() };
    setCustomFields(fields);
  };

  const recoverField = (index) => {
    const fields = [...customFields];
    fields[index] = { ...fields[index], deletedAt: null };
    setCustomFields(fields);
  };

  const addNewField = () => {
    const fields = [...customFields];
    fields.push({
      label: 'New Custom Field',
      order: fields.length + 1,
      type: 'text',
      SelectedType: fieldTypes.find((t) => t.value === 'text'),
      required: false,
      multiple: false,
      display_in_grid: false,
      searchable: false,
      mobile: false,
    });
    setCustomFields(fields);
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  const handleAddNewOption = (index) => {
    const fields = [...customFields];
    let options = fields[index].options;
    if (Array.isArray(options)) {
      options.push(fields[index].currentOption);
    } else {
      options = [fields[index].currentOption];
    }
    options.sort(alphaSort);
    fields[index] = { ...fields[index], options: options, currentOption: '' };
    setCustomFields(fields);
  };

  const deleteFieldOption = (index, optionIndex) => {
    const fields = [...customFields];
    let options = fields[index].options;
    options.splice(optionIndex, 1);
    options.sort(alphaSort);
    fields[index] = { ...fields[index], options: options };
    setCustomFields(fields);
  };

  const alphaSort = (a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) {
      return -1;
    }

    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    }

    return 0;
  };

  return (
    <Container maxWidth="xl">
      <Card>
        <CardHeader
          title="UPDATE SITE"
          sx={{ backgroundColor: '#275295', color: '#FFF' }}
        ></CardHeader>

        <CardContent>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Name"
                    id="Name"
                    name="name"
                    required={requiredFields.name}
                    value={details.name ?? ''}
                    onChange={handleFormChange}
                    error={formErrors.name}
                    helperText={formErrors.name}
                    onBlur={validate}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Description"
                    id="Description"
                    name="description"
                    required={requiredFields.description}
                    value={details.description ?? ''}
                    onChange={handleFormChange}
                    error={formErrors.description}
                    helperText={formErrors.description}
                    onBlur={validate}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <fieldset
                  style={{
                    borderRadius: 6,
                  }}
                >
                  <legend
                    style={{
                      backgroundColor: '#FFF',
                      padding: 10,
                      borderRadius: 6,
                    }}
                  >
                    Site Contact Info
                  </legend>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          label="Contact Name"
                          id="ContactName"
                          name="contact_name"
                          required={requiredFields.contact_name}
                          value={details.contact_name ?? ''}
                          onChange={handleFormChange}
                          error={formErrors.contact_name}
                          helperText={formErrors.contact_name}
                          onBlur={validate}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          label="Email"
                          id="Email"
                          name="contact_email"
                          required={requiredFields.contact_email}
                          value={details.contact_email ?? ''}
                          onChange={handleFormChange}
                          error={formErrors.contact_email}
                          helperText={formErrors.contact_email}
                          onBlur={validate}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          label="Phone Number"
                          id="PhoneNumber"
                          name="contact_phone"
                          required={requiredFields.contact_phone}
                          value={details.contact_phone ?? ''}
                          onChange={handleFormChange}
                          error={formErrors.contact_phone}
                          helperText={formErrors.contact_phone}
                          onBlur={validate}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </fieldset>
              </Grid>
              <Grid item xs={12}>
                <fieldset
                  style={{
                    borderRadius: 6,
                  }}
                >
                  <legend
                    style={{
                      backgroundColor: '#FFF',
                      padding: 10,
                      borderRadius: 6,
                    }}
                  >
                    Custom Fields
                  </legend>
                  <Grid container spacing={2}>
                    <Grid item xs={12} onClick={addNewField}>
                      <Button>Add New Field</Button>
                    </Grid>
                    {customFields
                      .sort((a, b) => a.order - b.order)
                      .map((field, index) => (
                        <Grid item xs={12}>
                          <Card
                            style={{
                              backgroundColor: '#E8E8E8',
                              opacity: field.deletedAt ? 0.65 : 1,
                              border: field.deletedAt ? '1px solid red ' : '',
                            }}
                          >
                            <CardContent>
                              <h3>Custom Field {index + 1}</h3>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <FormControl fullWidth>
                                    <TextField
                                      label="Label"
                                      id="Label"
                                      name="label"
                                      required
                                      disabled={field.deletedAt}
                                      value={field.label}
                                      onChange={(e) =>
                                        handleCustomFieldChange(e, index)
                                      }
                                      error={customFormErrors[index]}
                                      helperText={customFormErrors[index]}
                                      onBlur={validate}
                                    />
                                  </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                  <FormControl fullWidth>
                                    <Autocomplete
                                      name="SelectedType"
                                      label="Type"
                                      id="SelectedType"
                                      disabled={field.deletedAt}
                                      required
                                      value={field.SelectedType}
                                      onChange={(_, value) =>
                                        handleCustomFieldTypeChange(
                                          value,
                                          index
                                        )
                                      }
                                      error={formErrors.User}
                                      helperText={formErrors.User}
                                      onBlur={validate}
                                      options={fieldTypes}
                                      getOptionLabel={(option) => {
                                        return option.label;
                                      }}
                                      getOptionSelected={(option) => {
                                        return (
                                          option.value === field.SelectedType
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} label="Type" />
                                      )}
                                    />
                                  </FormControl>
                                </Grid>
                                {field?.SelectedType?.value === 'dropdown' && (
                                  <Grid item xs={12}>
                                    <fieldset
                                      style={{
                                        borderRadius: 6,
                                      }}
                                    >
                                      <legend
                                        style={{
                                          padding: 10,
                                          borderRadius: 6,
                                        }}
                                      >
                                        Dropdown Options
                                      </legend>
                                      <Grid container spacing={2}>
                                        <Grid item xs={8}>
                                          <FormControl fullWidth>
                                            <TextField
                                              label="New Dropdown Option"
                                              disabled={field.deletedAt}
                                              id="options"
                                              name="currentOption"
                                              value={field.currentOption ?? ''}
                                              onChange={(e) =>
                                                handleCustomFieldChange(
                                                  e,
                                                  index
                                                )
                                              }
                                              error={customFormErrors[index]}
                                              helperText={
                                                customFormErrors[index]
                                              }
                                              onBlur={validate}
                                            />
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <Button
                                            onClick={() => {
                                              handleAddNewOption(index);
                                            }}
                                            variant="contained"
                                          >
                                            Add New Option
                                          </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                          {field?.options
                                            ?.sort(alphaSort)
                                            ?.map((field, optionIndex) => (
                                              <Chip
                                                label={field}
                                                onDelete={() =>
                                                  deleteFieldOption(
                                                    index,
                                                    optionIndex
                                                  )
                                                }
                                                style={{ margin: 4 }}
                                              />
                                            ))}
                                        </Grid>
                                      </Grid>
                                    </fieldset>
                                  </Grid>
                                )}
                                <Grid
                                  item
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <FormControlLabel
                                    disabled={field.deletedAt}
                                    control={
                                      <Checkbox
                                        checked={field.mobile}
                                        onChange={() => {
                                          toggleCustomFieldCheckbox(
                                            'mobile',
                                            index
                                          );
                                        }}
                                      />
                                    }
                                    label="Display on Mobile"
                                  />
                                </Grid>
                                <Grid
                                  item
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <FormControlLabel
                                    disabled={field.deletedAt}
                                    control={
                                      <Checkbox
                                        checked={field.display_in_grid}
                                        onChange={() => {
                                          toggleCustomFieldCheckbox(
                                            'display_in_grid',
                                            index
                                          );
                                        }}
                                      />
                                    }
                                    label="Display in Grid"
                                  />
                                </Grid>
                                <Grid
                                  item
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <FormControlLabel
                                    disabled={field.deletedAt}
                                    control={
                                      <Checkbox
                                        checked={field.searchable}
                                        onChange={() => {
                                          toggleCustomFieldCheckbox(
                                            'searchable',
                                            index
                                          );
                                        }}
                                      />
                                    }
                                    label="Searchable"
                                  />
                                </Grid>
                                <Grid
                                  item
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <FormControlLabel
                                    disabled={
                                      field.SelectedType?.value === 'boolean' ||
                                      field.deletedAt
                                    }
                                    control={
                                      <Checkbox
                                        checked={field.required}
                                        onChange={() => {
                                          toggleCustomFieldCheckbox(
                                            'required',
                                            index
                                          );
                                        }}
                                      />
                                    }
                                    label="Required"
                                  />
                                </Grid>
                                <Grid
                                  item
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <FormControlLabel
                                    disabled={
                                      field.SelectedType?.value === 'boolean' ||
                                      field.deletedAt
                                    }
                                    control={
                                      <Checkbox
                                        checked={field.multiple}
                                        onChange={() => {
                                          toggleCustomFieldCheckbox(
                                            'multiple',
                                            index
                                          );
                                        }}
                                      />
                                    }
                                    label="Multiple Values"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Button
                                    disabled={index === 0 || field.deletedAt}
                                    onClick={() => moveFieldUp(index)}
                                    startIcon={<ExpandLessIcon />}
                                  >
                                    Move Order Up
                                  </Button>
                                  <Button
                                    disabled={index === 0 || field.deletedAt}
                                    onClick={() => moveFieldToTop(index)}
                                    startIcon={
                                      <FirstPageIcon
                                        style={{ transform: 'rotate(90deg)' }}
                                      />
                                    }
                                  >
                                    Move To Top
                                  </Button>
                                  <Button
                                    disabled={
                                      index === customFields.length - 1 ||
                                      field.deletedAt
                                    }
                                    onClick={() => moveFieldDown(index)}
                                    color="secondary"
                                    startIcon={<ExpandMoreIcon />}
                                  >
                                    Move Order Down
                                  </Button>
                                  <Button
                                    disabled={
                                      index === customFields.length - 1 ||
                                      field.deletedAt
                                    }
                                    onClick={() => moveFieldToBottom(index)}
                                    color="secondary"
                                    startIcon={
                                      <LastPageIcon
                                        style={{ transform: 'rotate(90deg)' }}
                                      />
                                    }
                                  >
                                    Move To Bottom
                                  </Button>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  style={{ textAlign: 'right' }}
                                >
                                  {field.deletedAt ? (
                                    <Button
                                      onClick={() => recoverField(index)}
                                      style={{ color: 'red' }}
                                    >
                                      Recover Field
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={() => deleteField(index)}
                                      style={{ color: 'red' }}
                                    >
                                      Delete Field
                                    </Button>
                                  )}
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    {customFields.length > 0 && (
                      <Grid item xs={12} onClick={addNewField}>
                        <Button>Add New Field</Button>
                      </Grid>
                    )}
                  </Grid>
                </fieldset>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            size="medium"
            variant="text"
            component={RouterLink}
            to={`/sites/${id}`}
          >
            CANCEL
          </Button>
          <Button
            size="medium"
            variant="contained"
            sx={{ backgroundColor: '#519801' }}
            onClick={submit}
          >
            UPDATE SITE
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
}
