import { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  Container,
  Button,
  IconButton,
  Box,
  Tabs,
  Tab,
  Tooltip,
} from '@mui/material';
import {
  Edit,
  Download,
  FolderZip,
  PictureAsPdf,
  Description,
  Html,
} from '@mui/icons-material';

import { Link as RouterLink, useParams } from 'react-router-dom';

import SiteDetails from './SiteDetails';
import SiteDetailsAssets from './SiteDetailsAssets';
import { nullifyEmptyString } from '../../utilities';
import fileDownload from 'js-file-download';

import API from '../../common/services/API';

export default function SiteDetailsContainer() {
  const [currentTab, setCurrentTab] = useState('details');
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [siteId, setSiteId] = useState();

  const defaultFormData = {
    completed: null,
    name: '',
    location: '',
    notes: '',
    alternate_location: '',
  };

  const [formData, setFormData] = useState(defaultFormData);

  const [customFormData, setCustomFormData] = useState({});

  // add all default states here name, type, etc
  const defaultFilters = {
    completed: null,
    name: '',
    location: null,
    hasPhotos: null,
    deletedAt: false,
  };

  // All custom Filters realted variables and functions
  const defaultCustomFilters = {};
  const [customFilters, setCustomFilters] = useState(defaultCustomFilters);
  // TODO: do we reset the customFilters in the existing resetCustomFilters function?

  // when the component loads
  const [filters, setFilters] = useState(defaultFilters);

  const [committedFilters, setCommittedFilters] = useState({});

  const [locations, setLocations] = useState([]);
  const [walkthroughs, setWalkthroughs] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [siteDetails, setSiteDetails] = useState([]);
  const [filterTimeout, setFilterTimeout] = useState();

  const [assets, setAssets] = useState([]);
  const [fields, setFields] = useState([]);

  const hideButton = true;

  const resetFilters = () => {
    setFilters(defaultFilters);
    // TODO: do we setCustomFilters(defaultCustomFilters) here?
  };
  const clearSelectedAsset = () => {
    setSelectedAsset(null);
    setFormData(defaultFormData);
    setCustomFormData({});
  };
  const handleChange = (event, tab) => {
    setCurrentTab(tab);
  };

  const selectAsset = (asset, existingMetaData = {}) => {
    setSelectedAsset(asset);
    const assetMeta = {};
    // when an asset is selected map over the assets AssetMetas
    asset.AssetMeta.forEach((meta) => {
      const foundField = fields.find((field) => {
        return field.id === meta.FieldId;
      });
      if (!foundField) {
        return;
      }
      // data manipulations based on types, etc, can be done here
      let value = meta.value;
      if (foundField.type === 'boolean') {
        value = meta.value === '1' ? true : false;
      }
      if (foundField.multiple === true) {
        if (!assetMeta[meta.FieldId]) {
          assetMeta[meta.FieldId] = [];
        }
        assetMeta[meta.FieldId].push(value);
      } else {
        // if there is only one value set it to the value

        assetMeta[meta.FieldId] = value;
      }
    });

    setCustomFormData({ ...assetMeta, ...existingMetaData });
  };

  const fetchData = async () => {
    setLoading(true);
    // const response = await API.get(`/sites/${id}`);
    // const walkthrough = response.data;
    const siteResponse = await API.get(`/sites/${id}`);
    setSiteDetails(siteResponse.data);
    setFields(siteResponse.data.Fields);
    setSiteId(siteResponse.data.id);
    const locationsResponse = await API.get(`/sites/${id}/locations`);
    const walkthroughsResponse = await API.get(`/sites/${id}/walkthroughs`);
    const walkthroughs = walkthroughsResponse.data;
    setWalkthroughs(walkthroughs);
    console.log('walkthroughs', walkthroughs);
    // setLocations(locationsResponse.data);
    // const locations = locationsResponse.data;
    const locations = locationsResponse.data;
    setLocations(locations);

    setLoading(false);
  };

  useEffect(() => fetchData(), []);

  useEffect(() => {
    clearTimeout(filterTimeout);

    setFilterTimeout(
      setTimeout(() => {
        setCommittedFilters({ ...filters });
      }, 300)
    );
  }, [filters]);

  const fetchAssets = async () => {
    setLoading(true);
    const assetResponse = await API.get(
      `/sites/${id}/assets`,
      nullifyEmptyString(filters)
    );

    setAssets(assetResponse.data);
    setLoading(false);
  };

  // when the assets change (when the fetchAssets is called)
  useEffect(() => {
    // if there is a selected asset, and check it has an id just to be sure
    if (selectedAsset && selectedAsset.id !== null) {
      // find the asset from the newly refreshed asset list
      const currentAsset = assets.find((a) => a.id === selectedAsset.id);
      if (currentAsset) {
        const formDataWithPhotos = {
          ...formData,
          Photos: currentAsset.Photos,
          AssetMeta: currentAsset.AssetMeta,
        };
        selectAsset(formDataWithPhotos, customFormData);
      }
    }
  }, [assets]);

  const exportSite = async () => {
    const response = await API.download(`/sites/${id}/export`);
    const date = new Date().toJSON().slice(0, 10);
    const filename = `${siteDetails.name}-${date}.xlsx`;
    fileDownload(response.data, filename);
  };

  const exportSiteAndPhotos = async () => {
    const response = await API.get(`/sites/${id}/full-export`);
    alert(
      'You should receive an email with your zip export download link once it is finished processing.'
    );
  };

  const exportPDF = async () => {
    const response = await API.get(`/sites/${id}/pdf`);
    alert(
      'You should receive an email with your pdf export download link once it is finished processing.'
    );
  };

  const exportHTML = async () => {
    const response = await API.get(`/sites/${id}/pdf?format=html`);
    alert(
      'You should receive an email with your html export download link once it is finished processing.'
    );
  };

  const exportDocx = async () => {
    const response = await API.get(`/sites/${id}/docx`);
    alert(
      'You should receive an email with your docx export download link once it is finished processing.'
    );
  };

  // after the debounce committed filters is set, causing a fetchdata to run with the new filters that were set so we dont search on every keypress or state change
  useEffect(fetchAssets, [committedFilters]);
  // console.log(assets);

  return (
    <Container maxWidth="xxl" disableGutters>
      <Card sx={{ borderRadius: 0 }}>
        <CardHeader
          title={siteDetails.name ? `${siteDetails.name} Overview` : '-'}
          sx={{
            backgroundColor: '#275295',
            color: '#FFF',
          }}
          action={
            <>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Button
                variant="contained"
                startIcon={<Edit />}
                sx={{ backgroundColor: '#519801' }}
                component={RouterLink}
                to={`/sites/${id}/edit`}
              >
                EDIT SITE
              </Button>
              <Tooltip title="Export data as .xlsx">
                <IconButton onClick={exportSite} sx={{ color: '#fff' }}>
                  <Download />
                </IconButton>
              </Tooltip>
              <Tooltip title="Export .zip with Photos">
                <IconButton
                  onClick={exportSiteAndPhotos}
                  sx={{ color: '#fff' }}
                >
                  <FolderZip />
                </IconButton>
              </Tooltip>
              {/* HIDE THIS */}
              {!hideButton && (
                <Tooltip title="Export PDF">
                  <IconButton onClick={exportPDF} sx={{ color: '#fff' }}>
                    <PictureAsPdf />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Export HTML">
                <IconButton onClick={exportHTML} sx={{ color: '#fff' }}>
                  <Html />
                </IconButton>
              </Tooltip>
              {/* HIDE THIS */}
              {!hideButton && (
                <Tooltip title="Export .docx">
                  <IconButton onClick={exportDocx} sx={{ color: '#fff' }}>
                    <Description />
                  </IconButton>
                </Tooltip>
              )}
            </>
          }
        ></CardHeader>

        <Box sx={{ width: '100%' }}>
          <Box>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              centered
              variant={'fullWidth'}
            >
              <Tab label="DETAILS" value="details" />
              <Tab label="ASSETS" value="assets" onClick={resetFilters} />
            </Tabs>
          </Box>
          {currentTab === 'details' && (
            <SiteDetails
              siteId={siteId}
              fetchData={fetchData}
              locations={locations}
              siteDetails={siteDetails}
              loading={loading}
              setFilters={setFilters}
              filters={filters}
              setCustomFilters={setCustomFilters}
              customFilters={customFilters}
            />
          )}
          {currentTab === 'assets' && (
            <SiteDetailsAssets
              walkthroughs={walkthroughs}
              selectAsset={selectAsset}
              site={siteDetails}
              defaultFilters={defaultFilters}
              loading={loading}
              setLoading={setLoading}
              resetFilters={resetFilters}
              setAssets={setAssets}
              assets={assets}
              setSelectedAsset={setSelectedAsset}
              selectedAsset={selectedAsset}
              filters={filters}
              setFilters={setFilters}
              customFilters={customFilters}
              setCustomFilters={setCustomFilters}
              defaultCustomFilters={defaultCustomFilters}
              clearSelectedAsset={clearSelectedAsset}
              fetchData={fetchData}
              fetchAssets={fetchAssets}
              formData={formData}
              setFormData={setFormData}
              customFormData={customFormData}
              setCustomFormData={setCustomFormData}
              setLocations={setLocations}
              locations={locations}
              siteId={siteId}
            />
          )}
        </Box>
      </Card>
    </Container>
  );
}
