import { useState, useEffect, useRef, useContext } from 'react';
import { nullifyEmptyString } from '../../utilities';

import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  CardActions,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  IconButton,
  InputAdornment,
  Autocomplete,
} from '@mui/material';

import { Clear as ClearIcon, Delete } from '@mui/icons-material';

import API from '../../common/services/API';
import LocationTree from './LocationTree';
import CardContentWithLoader from '../../common/components/CardContentWithLoader';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AuthContext } from '../../auth/context/AuthContext';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// import { useParams } from 'react-router-dom';

export default function AssetForm(props) {
  // const { id } = useParams();

  const toggleCompleted = () => {
    let value = !props.formData.completed;
    props.setFormData({ ...props.formData, completed: value });
    console.log(props.formData.completed);
  };

  const toggleCustomCheckbox = (name) => {
    let value = !props.customFormData[name];
    props.setCustomFormData({ ...props.customFormData, [name]: value });
    console.log(props.customFormData[name]);
  };

  console.log('rendering asset form');
  // const [state, setState] = useState({
  //   name: '',
  //   location: '',
  //   notes: '',
  // });

  const assetRef = useRef(null);
  const { user } = useContext(AuthContext);

  const scrollToAssetForm = () =>
    assetRef.current.scrollIntoView({ behavior: 'smooth' });

  const [formErrors, setFormErrors] = useState({});

  const [dialogOpen, setDialogOpen] = useState();

  const [newLocation, setNewLocation] = useState();

  const requiredFields = {
    name: true,
  };

  useEffect(() => {
    console.log(props.selectedAsset);
    if (props.selectedAsset) {
      const asset = props.selectedAsset;
      // TODO pull out values from asset.AssetMetas?
      // key in object is field id, asset[assetMeta.FieldId]
      asset.Location = getLocationTree(props.selectedAsset.LocationId);
      // props.fetchAssets();
      // TODO delete asset.AssetMetas - delete to exclude extra data unneeded
      props.setFormData(asset);
      scrollToAssetForm();
    }
  }, [props.selectedAsset]);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    // console.log('name:', name);
    // console.log('EVENT:', event);
    props.setFormData({ ...props.formData, [name]: value });
  };

  const handleCustomFormChange = (event, index = null) => {
    const { name, value } = event.target;
    if (index !== null) {
      const arrayValue = props.customFormData[name];
      arrayValue[index] = value;
      props.setCustomFormData({ ...props.customFormData, [name]: arrayValue });
    } else {
      props.setCustomFormData({ ...props.customFormData, [name]: value });
    }
  };

  const handleCustomFormDate = (fieldId, value, index = null) => {
    if (index !== null) {
      const arrayValue = props.customFormData[fieldId];
      arrayValue[index] = value;
      props.setCustomFormData({
        ...props.customFormData,
        [fieldId]: arrayValue,
      });
    } else {
      props.setCustomFormData({
        ...props.customFormData,
        [fieldId]: value,
      });
    }
  };

  const handleCustomFormAutocomplete = (event, name, value, index = null) => {
    // console.log('FFFFNAME:', name);
    // console.log('EVENT', event);
    // console.log('INDEX', index);
    // console.log('VALUE', value);
    // console.log('PROPPPPYS', props.customFormData);

    if (index !== null) {
      const arrayValue = props.customFormData[name];
      arrayValue[index] = value;
      props.setCustomFormData({
        ...props.customFormData,
        [name]: arrayValue,
      });
    } else {
      props.setCustomFormData({ ...props.customFormData, [name]: value });
    }
  };

  const handleDialogClose = async () => {
    setDialogOpen(false);
  };

  const confirmLocation = () => {
    // console.log('LOCAITON', getLocationTree(newLocation.id));
    props.setFormData({
      ...props.formData,
      LocationId: newLocation,
      Location: getLocationTree(newLocation),
    });
    handleDialogClose();
  };

  const fieldAdder = (type, id, value) => {
    // if the value is not an array do the following,
    // console.log('FORM DATA ON ADD:', props.customFormData);
    const newCustomFormData = { ...props.customFormData };

    if (!Array.isArray(value)) {
      newCustomFormData[id] = [value];
    }
    newCustomFormData[id].push(null);
    props.setCustomFormData(newCustomFormData);
    setFormErrors({});
  };

  const deleteMultipleField = (fieldId, index) => {
    const newCustomFormData = { ...props.customFormData };
    let newCustomFormDataArray = newCustomFormData[fieldId];

    // remove an item based on item index
    const removed = newCustomFormData[fieldId].splice(index, 1);

    if (newCustomFormDataArray.length < 2) {
      //  If the array has only one item convert to a string, this will remove the delete icon
      const singleValue = newCustomFormDataArray[0];
      newCustomFormData[fieldId] = singleValue;

      props.setCustomFormData(newCustomFormData);
    } else {
      //  if the array has more than two, keep it as an array
      props.setCustomFormData(newCustomFormData);
    }

    setFormErrors({});
  };

  // const multiChecker = (field, id) => {
  //   if (field.multiple === true && props.customFormData[field.id]) {
  //     props.setCustomFormData({
  //       ...props.customFormData,
  //       [id]: [value],
  //     });
  //   }
  // };

  const cancelLocation = () => {
    setNewLocation(null);
    handleDialogClose();
  };

  const validate = () => {
    const errors = {};
    for (const [field, required] of Object.entries(requiredFields)) {
      if (required && !props.formData[field]) {
        errors[field] = 'this field is required.';
      }
    }

    const requiredCustomFields = props.site.Fields.filter(
      (f) => f.required === true && f.type !== 'boolean'
    );

    for (const field of requiredCustomFields) {
      if (
        field.multiple === true &&
        Array.isArray(props.customFormData[field.id])
      ) {
        for (const [index, value] of props.customFormData[field.id].entries()) {
          if (!value) {
            console.log('no value', value, index);
            if (typeof errors[field.id] !== 'object') {
              console.log('typeof', typeof errors[field.id]);
              errors[field.id] = {};
            }
            errors[field.id][index] = 'this field is required.';
            console.log('errors', errors);
          }
        }
      } else if (!props.customFormData[field.id]) {
        errors[field.id] = 'this field is required.';
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return false;
    }

    setFormErrors({});
    return true;
  };

  const submit = async () => {
    if (!validate()) {
      return;
    }
    try {
      const data = {
        ...props.formData,
        CustomFields: props.customFormData,
      };
      // console.log('DATA', data);
      delete data.AssetMeta;
      // console.log('This is the data', data);
      const response = await API.put(
        `/assets/${props.selectedAsset.id}`,
        nullifyEmptyString(data)
      );
      // props.fetchData();
      props.fetchAssets();
      props.clearSelectedAsset();
      // call fetch data from props props.fetchdata()
    } catch (err) {
      alert('something went wrong');
      console.error(err);
    }
  };

  const getLocationTree = (locationId) => {
    // because we have to get data of unknown depth, it needs to be recursivename
    // recursive = function that calls itself

    // start building a string, starting with the "youngest" location
    // and we're going to prepent the parents, as we build the tree
    // get the asset's direct location name
    // Room 0
    // find the assets direct location parent
    // "Floor 0 > "Room 0
    // {parent.Name} > {asset.location.Name}
    // then find the last parents, parent
    // "Area 0 > "Floor 0 > Room 0
    // "Building 0 > " Area 0 > Floor 0 > Room 0

    // we check to make sure this asset even has a location
    if (!locationId) {
      return;
    }

    const currentLocation = props.locations.find((l) => l.id === locationId);

    if (!currentLocation) {
      return;
    }

    // get the array of all of the locations in the correct order
    const tree = buildLocationTree(currentLocation);

    // get just the names from the locations
    const names = tree.map((location) => location.name);

    // build a string from all of the locations by joining them with a carat and spaces
    return names.join(' > ');
  };

  // build a location tree for a location
  const buildLocationTree = (location, currentTree = []) => {
    // add the current location to the tree
    currentTree.unshift(location);

    // if the location doesn't have a parent, we're done
    if (!location.LocationId) {
      // return the current tree
      // this is our recursion escape
      return currentTree;
    }

    // find the parent location in our list of locations
    const newLocation = props.locations.find(
      (l) => l.id === location.LocationId
    );

    // this should really never happen, just an extra precaution
    if (!newLocation) {
      return currentTree;
    }

    // this is the start of our recursion
    return buildLocationTree(newLocation, currentTree);
  };

  return (
    <>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Select Location</DialogTitle>
        <DialogContent>
          <DialogContentText>Please select location below.</DialogContentText>
          <LocationTree
            locations={props.locations}
            onNodeSelect={(event, locationId) => {
              setNewLocation(locationId);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelLocation}>Cancel</Button>
          <Button
            // sx={{ backgroundColor: '#519801', color: '#fff' }}
            onClick={confirmLocation}
          >
            Set Location
          </Button>
        </DialogActions>
      </Dialog>

      <Card sx={{ backgroundColor: '#F0F1F9' }}>
        <CardHeader
          ref={assetRef}
          title="Asset Form"
          sx={{ backgroundColor: '#6E7B82', color: '#FFF' }}
        ></CardHeader>
        <CardContentWithLoader loading={props.loading}>
          {/* <form noValidate autoComplete="off"> */}

          <Grid container spacing={2} direction="column">
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  label="Name/ID"
                  variant="outlined"
                  name="name"
                  id="Name/ID"
                  shrink="true"
                  required={requiredFields.name}
                  value={props.formData.name ?? ''}
                  onChange={handleFormChange}
                  error={formErrors.name}
                  helperText={formErrors.name}
                  onBlur={validate}
                />
              </FormControl>
            </Grid>
            {user.admin === true && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    name="Walkthrough"
                    label="Walkthrough"
                    id="Walkthrough"
                    // required={requiredFields.User}
                    value={
                      props.formData.WalkthroughId
                        ? props.walkthroughs.find(
                            (w) => w.id === props.formData.WalkthroughId
                          )
                        : null
                    }
                    // onChange={handleFormChange}
                    onChange={(event, value) => {
                      if (!value) {
                        return;
                      }
                      props.setFormData({
                        ...props.formData,
                        WalkthroughId: value.id,
                      });
                    }}
                    isOptionEqualToValue={(option, value) => {
                      console.log('option', option, value);
                      return option.id === value;
                    }}
                    error={formErrors.Walkthrough}
                    helperText={formErrors.Walkthrough}
                    onBlur={validate}
                    options={props.walkthroughs}
                    getOptionLabel={(option) => {
                      return option.name;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Walkthrough" />
                    )}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item>
              <Box
                container
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <TextField
                  InputProps={{
                    readOnly: true,
                    // removed icon button in favor of textfield on click - save this for example
                    // endAdornment: (
                    //   <InputAdornment position="end">
                    //     <IconButton
                    //       onClick={() => {
                    //         props.setFormData({
                    //           ...props.formData,
                    //           LocationId: null,
                    //         });
                    //       }}
                    //     >
                    //       <ClearIcon />
                    //     </IconButton>
                    //   </InputAdornment>
                    // ),
                  }}
                  id="location"
                  label="Location"
                  // disabled="disabled"
                  shrink="true"
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                  // variant="outlined"
                  value={
                    props.formData.Location || ''
                    // props.selectedAsset.Location.name !== null &&
                    // props.selectedAsset.Location.name +
                    //   ' > ' +
                    //   props.selectedAsset.LocationId
                  }
                  fullWidth
                />
                <IconButton
                  sx={{ ml: '8px' }}
                  onClick={() => {
                    props.setFormData({
                      ...props.formData,
                      LocationId: null,
                      Location: null,
                    });
                    // props.fetchAssets();
                  }}
                >
                  <ClearIcon sx={{ display: 'flex' }} />
                </IconButton>
              </Box>
            </Grid>
            <Grid item>
              <TextField
                name="notes"
                value={props.formData.notes ?? ''}
                label={'Notes'}
                onChange={handleFormChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                name="alternate_location"
                value={props.formData.alternate_location ?? ''}
                label={'Alternate Location'}
                onChange={handleFormChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            {props?.site?.Fields &&
              props.site.Fields.sort((a, b) => {
                return a.order - b.order;
              }).map((field) => {
                // field.multiple = false;
                let component;
                let isFullWidth = true;
                let customSx;
                // console.log('field form map:', field);
                // TODO: if multiple is true in the create fake data 'Field'
                //  create a field set component that has an add button that calls a fiunction to add a dupe field
                // multiChecker();
                if (field.type === 'text') {
                  if (Array.isArray(props.customFormData[field.id])) {
                    component = props.customFormData[field.id].map(
                      (value, index) => {
                        return (
                          <>
                            {props.customFormData[field.id].length > 1 && (
                              <Grid item xs={1} alignSelf={'center'}>
                                <Delete
                                  onClick={(event) => {
                                    deleteMultipleField(field.id, index);
                                  }}
                                />
                              </Grid>
                            )}
                            <Grid item xs={11}>
                              <FormControl fullWidth={isFullWidth}>
                                <TextField
                                  variant="outlined"
                                  name={field.id}
                                  id={field.id}
                                  required={field.required}
                                  value={value ?? ''}
                                  onChange={(event) => {
                                    handleCustomFormChange(event, index);
                                  }}
                                  error={formErrors?.[field.id]?.[index]}
                                  helperText={formErrors?.[field.id]?.[index]}
                                  onBlur={validate}
                                />
                              </FormControl>
                            </Grid>
                          </>
                        );
                      }
                    );
                  } else {
                    component = (
                      <Grid item xs={12}>
                        <FormControl fullWidth={isFullWidth}>
                          <TextField
                            label={field.multiple ? undefined : field.label}
                            variant="outlined"
                            name={field.id}
                            id={field.id}
                            required={field.required}
                            value={props.customFormData[field.id] ?? ''}
                            onChange={handleCustomFormChange}
                            error={
                              typeof formErrors[field.id] === 'object'
                                ? null
                                : formErrors[field.id]
                            }
                            helperText={
                              typeof formErrors[field.id] === 'object'
                                ? null
                                : formErrors[field.id]
                            }
                            onBlur={validate}
                          />
                        </FormControl>
                      </Grid>
                    );
                  }
                } else if (field.type === 'textarea') {
                  if (Array.isArray(props.customFormData[field.id])) {
                    component = props.customFormData[field.id].map(
                      (value, index) => {
                        return (
                          <>
                            {props.customFormData[field.id].length > 1 && (
                              <Grid item xs={1} alignSelf={'center'}>
                                <Delete
                                  onClick={(event) => {
                                    deleteMultipleField(field.id, index);
                                  }}
                                />
                              </Grid>
                            )}
                            <Grid item xs={11}>
                              <FormControl fullWidth={isFullWidth}>
                                <TextField
                                  variant="outlined"
                                  name={field.id}
                                  id={field.id}
                                  required={field.required}
                                  value={value ?? ''}
                                  onChange={(event) => {
                                    handleCustomFormChange(event, index);
                                  }}
                                  error={formErrors?.[field.id]?.[index]}
                                  helperText={formErrors?.[field.id]?.[index]}
                                  onBlur={validate}
                                  multiline
                                  rows={4}
                                />
                              </FormControl>
                            </Grid>
                            {/* </Grid> */}
                          </>
                        );
                      }
                    );
                  } else {
                    component = (
                      <Grid item xs={12}>
                        <FormControl fullWidth={isFullWidth}>
                          <TextField
                            label={field.multiple ? undefined : field.label}
                            variant="outlined"
                            name={field.id}
                            id={field.id}
                            required={field.required}
                            value={props.customFormData[field.id] ?? ''}
                            onChange={handleCustomFormChange}
                            error={
                              typeof formErrors[field.id] === 'object'
                                ? null
                                : formErrors[field.id]
                            }
                            helperText={
                              typeof formErrors[field.id] === 'object'
                                ? null
                                : formErrors[field.id]
                            }
                            onBlur={validate}
                            multiline
                            rows={4}
                          />
                        </FormControl>
                      </Grid>
                    );
                  }
                } else if (field.type === 'dropdown') {
                  if (Array.isArray(props.customFormData[field.id])) {
                    component = props.customFormData[field.id].map(
                      (value, index) => {
                        return (
                          <>
                            {props.customFormData[field.id].length > 1 && (
                              <Grid item xs={1} alignSelf={'center'}>
                                <Delete
                                  onClick={(event) => {
                                    deleteMultipleField(field.id, index);
                                  }}
                                />
                              </Grid>
                            )}
                            <Grid item xs={11}>
                              <FormControl fullWidth={isFullWidth}>
                                <Autocomplete
                                  variant="outlined"
                                  name={field.id}
                                  id={field.id}
                                  options={field.options}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required={field.required}
                                      error={formErrors?.[field.id]?.[index]}
                                      helperText={
                                        formErrors?.[field.id]?.[index]
                                      }
                                    />
                                  )}
                                  getOptionLabel={(option) => {
                                    return option;
                                  }}
                                  required={field.required}
                                  value={value ?? null}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                  // defaultValue={props.setCustomFormData[field.label ?? '']}

                                  onChange={(event, value) => {
                                    handleCustomFormAutocomplete(
                                      event,
                                      field.id,
                                      value,
                                      index
                                    );
                                  }}
                                  // onBlur={validate}
                                  // multiline
                                />
                              </FormControl>
                            </Grid>
                            {/* </Grid> */}
                          </>
                        );
                      }
                    );
                  } else {
                    component = (
                      <Grid item xs={12}>
                        <FormControl fullWidth={isFullWidth}>
                          <Autocomplete
                            label={field.label}
                            variant="outlined"
                            name={field.id}
                            id={field.id}
                            options={field.options}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={field.multiple ? undefined : field.label}
                                required={field.required}
                                error={
                                  typeof formErrors[field.id] === 'object'
                                    ? null
                                    : formErrors[field.id]
                                }
                                helperText={
                                  typeof formErrors[field.id] === 'object'
                                    ? null
                                    : formErrors[field.id]
                                }
                              />
                            )}
                            getOptionLabel={(option) => {
                              return option;
                            }}
                            required={field.required}
                            value={props.customFormData[field.id] ?? null}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            // defaultValue={props.setCustomFormData[field.label ?? '']}

                            onChange={(event, value) => {
                              handleCustomFormAutocomplete(
                                event,
                                field.id,
                                value
                              );
                            }}
                            // onBlur={validate}
                            // multiline
                          />
                        </FormControl>
                      </Grid>
                    );
                  }
                } else if (field.type === 'number') {
                  if (Array.isArray(props.customFormData[field.id])) {
                    component = props.customFormData[field.id].map(
                      (value, index) => {
                        return (
                          <>
                            {props.customFormData[field.id].length > 1 && (
                              <Grid item xs={1} alignSelf={'center'}>
                                <Delete
                                  onClick={(event) => {
                                    deleteMultipleField(field.id, index);
                                  }}
                                />
                              </Grid>
                            )}
                            <Grid item xs={11}>
                              <FormControl fullWidth={isFullWidth}>
                                <TextField
                                  variant="outlined"
                                  type="number"
                                  name={field.id}
                                  id={field.id}
                                  required={field.required}
                                  value={value ?? ''}
                                  onChange={(event) => {
                                    handleCustomFormChange(event, index);
                                  }}
                                  error={formErrors?.[field.id]?.[index]}
                                  helperText={formErrors?.[field.id]?.[index]}
                                  onBlur={validate}
                                  // multiline
                                />
                              </FormControl>
                            </Grid>
                            {/* </Grid> */}
                          </>
                        );
                      }
                    );
                  } else {
                    component = (
                      <Grid item xs={12}>
                        <FormControl fullWidth={isFullWidth}>
                          <TextField
                            label={field.multiple ? undefined : field.label}
                            variant="outlined"
                            type="number"
                            name={field.id}
                            id={field.id}
                            required={field.required}
                            value={props.customFormData[field.id] ?? ''}
                            onChange={handleCustomFormChange}
                            error={
                              typeof formErrors[field.id] === 'object'
                                ? null
                                : formErrors[field.id]
                            }
                            helperText={
                              typeof formErrors[field.id] === 'object'
                                ? null
                                : formErrors[field.id]
                            }
                            onBlur={validate}
                            // multiline
                          />
                        </FormControl>
                      </Grid>
                    );
                  }
                } else if (field.type === 'boolean') {
                  customSx = { display: 'flex', justifyContent: 'flex-end' };
                  // isFlexEnd = 'flex-start';
                  isFullWidth = false;
                  component = (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={props.customFormData[field.id] ?? false}
                          onChange={() => {
                            toggleCustomCheckbox(field.id);
                          }}
                        />
                      }
                      label={field.label}
                    />
                  );
                } else if (field.type === 'date') {
                  if (Array.isArray(props.customFormData[field.id])) {
                    component = props.customFormData[field.id].map(
                      (value, index) => {
                        return (
                          <>
                            {props.customFormData[field.id].length > 1 && (
                              <Grid item xs={1} alignSelf={'center'}>
                                <Delete
                                  onClick={(event) => {
                                    deleteMultipleField(field.id, index);
                                  }}
                                />
                              </Grid>
                            )}
                            <Grid item xs={11}>
                              <FormControl fullWidth={isFullWidth}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <DatePicker
                                    // label={field.label}
                                    value={value ?? null}
                                    onChange={(newValue) => {
                                      handleCustomFormDate(
                                        field.id,
                                        newValue,
                                        index
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        required={field.required}
                                        error={formErrors?.[field.id]?.[index]}
                                        helperText={
                                          formErrors?.[field.id]?.[index]
                                        }
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </FormControl>
                            </Grid>
                            {/* </Grid> */}
                          </>
                        );
                      }
                    );
                  } else {
                    component = (
                      <Grid item xs={12}>
                        <FormControl fullWidth={isFullWidth}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label={field.multiple ? undefined : field.label}
                              value={props.customFormData[field.id] ?? null}
                              onChange={(newValue) => {
                                props.setCustomFormData({
                                  ...props.customFormData,
                                  [field.id]: newValue,
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required={field.required}
                                  error={
                                    typeof formErrors[field.id] === 'object'
                                      ? null
                                      : formErrors[field.id]
                                  }
                                  helperText={
                                    typeof formErrors[field.id] === 'object'
                                      ? null
                                      : formErrors[field.id]
                                  }
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>
                    );
                  }
                } else if (field.type === 'datetime') {
                  if (Array.isArray(props.customFormData[field.id])) {
                    component = props.customFormData[field.id].map(
                      (value, index) => {
                        return (
                          <>
                            {props.customFormData[field.id].length > 1 && (
                              <Grid item xs={1} alignSelf={'center'}>
                                <Delete
                                  onClick={(event) => {
                                    deleteMultipleField(field.id, index);
                                  }}
                                />
                              </Grid>
                            )}
                            <Grid item xs={11}>
                              <FormControl fullWidth={isFullWidth}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <DateTimePicker
                                    // label={field.label}
                                    value={value ?? null}
                                    onChange={(newValue) => {
                                      handleCustomFormDate(
                                        field.id,
                                        newValue,
                                        index
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        required={field.required}
                                        error={formErrors?.[field.id]?.[index]}
                                        helperText={
                                          formErrors?.[field.id]?.[index]
                                        }
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </FormControl>
                            </Grid>
                            {/* </Grid> */}
                          </>
                        );
                      }
                    );
                  } else {
                    component = (
                      <Grid item xs={12}>
                        <FormControl fullWidth={isFullWidth}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                              label={field.multiple ? undefined : field.label}
                              value={props.customFormData[field.id] ?? null}
                              onChange={(newValue) => {
                                props.setCustomFormData({
                                  ...props.customFormData,
                                  [field.id]: newValue,
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required={field.required}
                                  error={
                                    typeof formErrors[field.id] === 'object'
                                      ? null
                                      : formErrors[field.id]
                                  }
                                  helperText={
                                    typeof formErrors[field.id] === 'object'
                                      ? null
                                      : formErrors[field.id]
                                  }
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>
                    );
                  }
                }

                if (field.multiple === true && field.type !== 'boolean') {
                  return (
                    <fieldset
                      style={{
                        borderRadius: 6,
                        marginLeft: 18,
                      }}
                    >
                      <legend
                        style={{
                          padding: 10,
                          borderRadius: 6,
                        }}
                      >
                        {field.label} {field.required === true && ' *'}
                      </legend>

                      <Grid container sx={customSx}>
                        <Grid item xs={11}>
                          <Grid container spacing={2}>
                            {component}
                          </Grid>
                        </Grid>
                        <Grid item xs={1} alignSelf={'flex-end'} flexGrow>
                          <Button
                            onClick={() => {
                              fieldAdder(
                                field.type,
                                field.id,
                                props.customFormData[field.id]
                              );
                            }}
                          >
                            Add
                          </Button>
                        </Grid>
                      </Grid>
                    </fieldset>
                  );
                }

                return (
                  <Grid item sx={customSx}>
                    <FormControl fullWidth={isFullWidth}>
                      {component}
                    </FormControl>
                  </Grid>
                );
              })}
            <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formData.completed}
                    onChange={() => {
                      toggleCompleted();
                    }}
                  />
                }
                label="Completed"
              />
            </Grid>
          </Grid>
        </CardContentWithLoader>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            variant="text"
            onClick={() => {
              props.clearSelectedAsset();
            }}
          >
            Cancel
          </Button>
          <Button
            size="medium"
            variant="contained"
            sx={{ backgroundColor: '#519801' }}
            onClick={submit}
          >
            UPDATE ASSET
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
