export default function User(user) {
  console.log(user);
  this.id = null;
  this.name = null;
  this.email = null;
  this.admin = null;

  if ('id' in user) {
    this.id = user.id;
  }

  if ('name' in user) {
    this.name = user.name;
  }

  if ('email' in user) {
    this.email = user.email;
  }

  if ('admin' in user) {
    this.admin = user.admin;
  }

  this.isAdmin = function () {
    return this.admin === true;
  };
}
