import { useState, useEffect, useContext } from 'react';
import { Add } from '@mui/icons-material';
import { TreeItem } from '@mui/lab';

import {
  Card,
  CardHeader,
  Button,
  Box,
  Grid,
  CardContent,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  CircularProgress,
  Text,
  Typography,
} from '@mui/material';

import API from '../../common/services/API';
import { AuthContext } from '../../auth/context/AuthContext';
import CardContentWithLoader from '../../common/components/CardContentWithLoader';

import LocationTree from './LocationTree';
// import {Close as CloseIcon} from "@mui/icons-material";

export default function AssetLocations(props) {
  const [contextMenu, setContextMenu] = useState(null);

  const [dialogOpen, setDialogOpen] = useState();

  const { user } = useContext(AuthContext);

  const defaultState = {
    name: null,
    LocationId: null,
    SiteId: null,
    id: null,
  };

  const [state, setState] = useState(defaultState);

  const handleContextMenu = (event, location) => {
    if (user.admin === true) {
      console.log(event);
      // prevent the normal right click menu from appearing
      event.preventDefault();
      // prevent the event from bubbling up to the parents
      event.stopPropagation();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            }
          : null
      );
      setState({ ...state, LocationId: location.id });
      console.log('right clicked location state', state);
    }
  };

  // closes the right click menu by setting menu to null
  const handleContextClose = () => {
    setContextMenu(null);
    setState({ ...state, name: null, id: null });
  };

  const openDialogContextMenu = async () => {
    // close context menu
    handleContextClose();
    // open a dialogue, prompt inputs
    setDialogOpen(true);
    // submit call with object
    // const response = await API.put('/locations', locationObject);
    // close dialogue
    // handleClose();
  };

  const openDialogButton = async () => {
    // close right click menu if open
    handleContextClose();
    // open the add/update location dialog
    setDialogOpen(true);
    // set the locationId to null as we are adding a new top level location
    setState({ SiteId: props.siteId, LocationId: null, id: null, name: null });
    console.log('open dialog state', state);
  };

  const handleDialogClose = async () => {
    // close the add/update dialog
    setDialogOpen(false);
    // clear out the state
    setState({ ...state, name: null, LocationId: null, id: null });
    console.log('Dialog closed state', state);
  };

  const renameLocation = async () => {
    console.log('Rename', state);
    // find the location by id that matches the state location (right clicked location)
    const renamedLocation = props.locations.find((e) => {
      return e.id === state.LocationId;
    });
    setState(renamedLocation);
    console.log('renamed location', renamedLocation);

    setDialogOpen(true);
    setContextMenu(null);
  };

  const [formErrors, setFormErrors] = useState({});

  const requiredFields = {
    name: true,
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    // the [name] allows us to use a variable as a property name
    setState({ ...state, [name]: value });
  };

  // //----- edit this
  const validate = () => {
    // check for required fields
    const errors = {};
    // Object.entries allows us to loop over an object as if it were an array
    // and we can get the key and value
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
    for (const [field, required] of Object.entries(requiredFields)) {
      if (required && !state[field]) {
        // then there is an error
        errors[field] = 'This field is required.';
      }
    }

    // check if there were any errors
    // we use the Object.keys to get all of the keys from the object as an array
    // then we check the length to determine if there were any errors
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return false;
    }

    // reset the form errors to an empty object if there are no errors
    setFormErrors({});
    return true;
  };

  const submit = async () => {
    if (!validate()) {
      return;
    }

    // {(state.id !== null && <DialogTitle> Rename Location</DialogTitle>) || (
    //   <DialogTitle>Add New Location</DialogTitle>
    // )}

    // if state has no location.id

    if (state.id !== null) {
      // console.log('STATE PRIOR TO POST', state);
      try {
        const addResponse = await API.put(`/locations/${state.id}`, state);
        console.log('ASSET RENAMED', addResponse);
        props.fetchData();
        // const id = response.data.id;
      } catch (err) {
        // TODO: replace this a snackbar or something
        alert('Something went wrong');
        console.error(err);
      }
    } else {
      try {
        const updateResponse = await API.post('/locations', state);
        console.log('NEW ASSET ADDED', updateResponse);
        props.fetchData();
      } catch (err) {
        alert('Something went wrong');
        console.error(err);
      }
    }

    handleDialogClose();
  };

  const handleDelete = async () => {
    try {
      const response = await API.delete(`/locations/${state.LocationId}`);
      props.fetchData();
      handleContextClose();
      // const id = response.data.id;
      // if successful, refresh data
    } catch (err) {
      alert(err.response?.data?.message || 'There was a server error');
      console.log(err);
    }
  };

  useEffect(() => {
    console.log(props.siteId);
    console.log('HERE ARE THE PROOS:', props);
    setState({ ...state, SiteId: props.siteId });
  }, [props.siteId]);

  return (
    <>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        {/* conditional statement for displaying correct title */}
        {/* {state.id === null && <DialogTitle>Add New Location</DialogTitle>} */}
        <DialogTitle>
          {state.id === null ? 'Add New Location' : 'Rename Location'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter location name below.
          </DialogContentText>
          <TextField
            autoFocus
            fullWidth
            variant="standard"
            name="name"
            label="Location Name"
            required={requiredFields.name}
            id="Location Name"
            value={state.name ?? ''}
            onChange={handleFormChange}
            error={formErrors.name}
            helperText={formErrors.name}
            onBlur={validate}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button
            // sx={{ backgroundColor: '#519801', color: '#fff' }}
            onClick={submit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Menu
        open={contextMenu !== null}
        onClose={handleContextClose}
        // onBlur={handleContextClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            openDialogContextMenu();
            console.log('State from Add context menu', state);
          }}
        >
          Add
        </MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
        <MenuItem onClick={renameLocation}>Rename</MenuItem>
        <MenuItem onClick={handleContextClose}>Close</MenuItem>
      </Menu>

      <Card sx={{ backgroundColor: '#F0F1F9' }}>
        <CardHeader
          title="Locations"
          sx={{ backgroundColor: '#6E7B82', color: '#FFF' }}
          action={
            <>
              <Box sx={{ flexGrow: 1 }}></Box>

              {user.admin === true && (
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  sx={{ backgroundColor: '#519801' }}
                  onClick={openDialogButton}
                >
                  ADD
                </Button>
              )}

              {!props.disableFilters && (
                <Button
                  variant="text"
                  sx={{ color: '#fff' }}
                  onClick={() => {
                    props.setFilters({ ...props.filters, location: null });
                  }}
                >
                  ALL LOCATIONS
                </Button>
              )}
            </>
          }
        ></CardHeader>
        <CardContentWithLoader loading={props.loading}>
          <Grid container>
            <Grid item>
              <LocationTree
                locations={props.locations}
                onNodeSelect={(event, locationId) => {
                  if (props.disableFilters) {
                    return;
                  }
                  props.setFilters({ ...props.filters, location: locationId });
                }}
                handleContextMenu={handleContextMenu}
              />
              <Typography
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgb(233,233,240)',
                  },
                  marginLeft: 1,
                }}
                style={{
                  cursor: 'pointer',
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
                // onHover={}
                onClick={() => {
                  console.log('location set to null');
                  // props.setFilters({ ...props.filters, location: null });

                  props.setFilters({
                    ...props.filters,
                    location: 'notlisted',
                  });
                }}
              >
                {'Location not listed'}
              </Typography>
            </Grid>
          </Grid>
        </CardContentWithLoader>
      </Card>
    </>
  );
}
