import React, { useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { red } from '@mui/material/colors';

export default function Dropzone(props) {
  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone(
    props.params || {}
  );

  const borderFontColor = 'rgba(0,0,0,0.23)';

  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: borderFontColor,
    borderStyle: 'dashed',
    // backgroundColor: '#F0F1F9',
    color: borderFontColor,
  };

  useEffect(() => {
    console.log({ acceptedFiles });
    if (props.onFileAdded && acceptedFiles.length > 0) {
      props.onFileAdded(acceptedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  return (
    <>
      <div {...getRootProps({ className: 'dropzone', style })}>
        <input {...getInputProps()} />
        <CloudUploadIcon sx={{ paddingRight: 2 }} />
        <Typography
          onClick={(e) => {
            e.stopPropagation();
            open();
          }}
          variant="h5"
        >
          Drag and drop a file here or click
        </Typography>
      </div>
    </>
  );
}
