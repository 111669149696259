import {
  Card,
  CardHeader,
  Container,
  Grid,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import { Visibility } from '@mui/icons-material';

import { GridActionsCellItem, DataGrid, GridColType } from '@mui/x-data-grid';

import { Link as RouterLink } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import API from '../../common/services/API';
import { AuthContext } from '../../auth/context/AuthContext';

export default function MyWalkthroughs() {
  const [walkthroughs, setWalkthroughs] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);

  function NoRowsOverlay() {
    return (
      <Stack
        top="60%"
        alignItems="center"
        position="relative"
        justifyContent="center"
      >
        You have no assigned walkthroughs.
      </Stack>
    );
  }

  const fetchData = async () => {
    setLoading(true);
    const response = await API.get(`/users/walkthroughs`);
    console.log('walkthroughs', response.data);
    setWalkthroughs(response.data);
    setLoading(false);
  };

  useEffect(() => fetchData(), []);

  const columns = [
    {
      field: 'actions',
      headerName: 'Actions',
      headerClassName: 'secondary-color-header',
      type: 'actions',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 85,
      flex: 0.4,
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={<Visibility />}
          component={RouterLink}
          to={`/walkthroughs/${row.id}`}
        />,
      ],
    },
    {
      field: 'Site',
      headerClassName: 'secondary-color-header',
      headerAlign: 'left',
      headerName: 'Site Name',
      minWidth: 100,
      disableColumnMenu: true,
      flex: 6,
      valueGetter: (params) => params.row.Site.name,
    },
    {
      field: 'name',
      headerClassName: 'secondary-color-header',
      headerAlign: 'left',
      headerName: 'Name',
      minWidth: 100,
      disableColumnMenu: true,
      flex: 6,
      // valueGetter: (params) => console.log('PARAMS IN GRID', params),
      valueGetter: (params) => params.row.name || '-',
    },
    {
      field: 'status',
      headerClassName: 'secondary-color-header',
      headerAlign: 'left',
      headerName: 'Status',
      minWidth: 100,
      disableColumnMenu: true,
      flex: 6,
      valueGetter: (params) => params.row.status || '-',
    },
    {
      field: 'progress',
      headerClassName: 'secondary-color-header',
      headerAlign: 'left',
      headerName: 'Progress',
      minWidth: 100,
      disableColumnMenu: true,
      flex: 6,
      valueGetter: (params) => {
        let string = params.row.progress ? `${params.row.progress}%` : '-';

        if (
          params.row.progress &&
          params.row.assetsCount !== null &&
          params.row.completedAssetsCount !== null
        ) {
          string += ` (${params.row.completedAssetsCount}/${params.row.assetsCount})`;
        }

        return string;
      },
    },
    {
      field: 'due_date',
      headerClassName: 'secondary-color-header',
      type: 'date',
      headerAlign: 'left',
      headerName: 'Due Date',
      minWidth: 100,
      disableColumnMenu: true,
      flex: 6,
      disableColumnMenu: true,
      valueFormatter: (params) => {
        if (params.value === null) {
          return '-';
        }
        return new Date(params.value).toLocaleDateString();
      },
    },
  ];

  return (
    <Container maxWidth="xl">
      <Card>
        <CardHeader
          title="MY WALKTHROUGHS"
          sx={{ backgroundColor: '#275295', color: '#FFF' }}
        ></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                To login to mobile app, use your email and the following code:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {' '}
                  {user.id ? user.id.split('-')[0] : '-'}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ backgroundColor: '#F0F1F9' }}>
                <CardHeader
                  title="Assigned Walkthroughs"
                  sx={{ backgroundColor: '#6E7B82', color: '#FFF' }}
                ></CardHeader>
                <Grid container>
                  <DataGrid
                    sx={{
                      '& .secondary-color-header': {
                        backgroundColor: '#C4CBD6',
                      },
                    }}
                    loading={loading}
                    disableSelectionOnClick
                    autoHeight
                    rows={walkthroughs}
                    columns={columns}
                    components={{ NoRowsOverlay }}
                  />
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}
