import {
  Card,
  CardHeader,
  Grid,
  IconButton,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField,
  Stack,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Delete, Visibility, Add, Restore } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';

import { AuthContext } from '../../auth/context/AuthContext';

import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import API from '../../common/services/API';

export default function AssetList(props) {
  const { id } = useParams();

  const [pageSize, setPageSize] = useState(15);
  const { user } = useContext(AuthContext);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [columns, setColumns] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState({});

  const [assetToDelete, setAssetToDelete] = useState(null);

  const handleDeleteDialog = () => {
    setConfirmDeleteOpen(!confirmDeleteOpen);
    console.log('open dialog');
  };

  const confirmDeleteAsset = async () => {
    try {
      const response = await API.delete(`/assets/${assetToDelete.id}`);
      handleDeleteDialog();
      props.clearSelectedAsset();
      props.fetchAssets();
      setAssetToDelete(null);
    } catch (err) {
      alert(err.response?.data?.message || 'There was a server error');
      console.log(err);
    }
  };

  function NoRowsOverlay() {
    if (
      props.filters.completed === null &&
      props.filters.name === '' &&
      props.filters.location === null
    ) {
      return (
        <Stack
          top="60%"
          alignItems="center"
          position="relative"
          justifyContent="center"
        >
          There are no associated assets.
        </Stack>
      );
    } else {
      return (
        <Stack
          top="60%"
          alignItems="center"
          position="relative"
          justifyContent="center"
        >
          There were not results for your filters.
        </Stack>
      );
    }
  }

  const createAsset = async () => {
    try {
      const response = await API.post('/assets', {
        name: 'New Asset',
        WalkthroughId: `${id}`,
        LocationId: props.filters.location,
      });
      props.setSelectedAsset(response.data);
      props.fetchAssets();
      console.log('FILTERS', props.filters);
    } catch (err) {
      // TODO: replace this a snackbar or something
      alert('Something went wrong');
      console.error(err);
    }
  };

  const deleteAsset = async (row) => {
    console.log({ row });
    setAssetToDelete(row);
    handleDeleteDialog();
  };

  const restoreAsset = async (row) => {
    try {
      const response = await API.put(`/assets/${row.id}/restore`);
      props.fetchAssets();
    } catch (err) {
      alert(err.response?.data?.message || 'There was a server error');
      console.log(err);
    }
  };

  const getAssetListActions = ({ row }) => {
    const actions = [];

    if (user.admin === true) {
      if (props.filters.deletedAt === true) {
        actions.push(
          <GridActionsCellItem
            icon={<Restore />}
            label="Restore"
            onClick={() => {
              restoreAsset(row);
            }}
          />
        );
      } else {
        actions.push(
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={() => {
              deleteAsset(row);
            }}
          />
        );
      }
    }

    actions.push(
      <GridActionsCellItem
        icon={<Visibility />}
        label="Select"
        onClick={() => {
          props.selectAsset(row);
          // props.setSelectedAsset(null);
          // props.setSelectedAsset(row);
        }}
      />
    );

    return actions;
  };
  const formatColumns = () => {
    const columns = [
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        flex: 0.1,
        minWidth: 100,
        getActions: getAssetListActions,
        hideable: false,
      },
      {
        field: 'name',
        headerName: 'Name/ID',
        disableColumnMenu: true,
        flex: 1,
        minWidth: 100,
        hideable: false,
      },
      // {
      //   field: 'type',
      //   headerName: 'Type',
      //   disableColumnMenu: true,
      //   flex: 1,
      // },
      {
        field: 'alternate_location',
        headerName: 'Alternate Location',
        flex: 1,
        minWidth: 200,
        disableColumnMenu: true,
      },
      {
        field: 'LocationTree',
        headerName: 'Location',
        flex: 1,
        minWidth: 200,
        disableColumnMenu: true,
      },
      {
        field: 'notes',
        headerName: 'Notes',
        flex: 1,
        minWidth: 200,
        disableColumnMenu: true,
      },
      {
        field: 'photoCount',
        headerName: 'Photos',
        type: 'number',
        flex: 1,
        minWidth: 80,
        disableColumnMenu: true,
        valueFormatter: (params) => {
          if (params.value === 0) {
            return '-';
          }
          return params.value;
        },
      },
      {
        field: 'completed',
        headerName: 'Completed',
        type: 'boolean',
        flex: 1,
        minWidth: 110,
        disableColumnMenu: true,
      },
      {
        field: 'updatedAt',
        headerClassName: 'secondary-color-header',
        type: 'date',
        headerAlign: 'left',
        headerName: 'Last Updated',
        flex: 1,
        minWidth: 150,
        disableColumnMenu: true,
        valueFormatter: (params) => {
          if (params.value === null) {
            return;
          }
          return new Date(params.value).toLocaleString([], {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          });
        },
      },
    ];

    const viewableFields = props?.site?.Fields.filter(
      (f) => f.display_in_grid === true
    );

    if (viewableFields) {
      viewableFields.forEach((f) => {
        const fieldConfig = {
          field: `AssetMeta.${f.id}`,
          headerName: f.label,
          flex: 1,
          minWidth: 200,
          disableColumnMenu: true,
        };
        if (f.type === 'boolean') {
          fieldConfig.type = 'boolean';
          fieldConfig.valueGetter = (params) => {
            if (params.value === null || params.value === undefined) {
              return false;
            }
            return params.value === '1';
          };
        } else if (f.type === 'date') {
          fieldConfig.type = 'date';
          fieldConfig.valueFormatter = (params) => {
            if (params.value === null || params.value === undefined) {
              return;
            }
            return new Date(params.value).toLocaleString([], {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            });
          };
        } else if (f.type === 'datetime') {
          fieldConfig.type = 'dateTime';
          fieldConfig.valueFormatter = (params) => {
            if (params.value === null || params.value === undefined) {
              return;
            }
            return new Date(params.value).toLocaleString([], {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            });
          };
        } else if (f.type === 'dropdown') {
          fieldConfig.type = 'singleSelect';
          console.log(f.options);
          fieldConfig.valueOptions = f.options.map((f) => {
            return { value: f, label: f };
          });
        }
        columns.push(fieldConfig);
      });
    }

    setColumns(columns);

    loadColumnVisibilityFromLocalStorage();
  };

  useEffect(() => {
    formatColumns();
  }, [props.site, props.filters]);

  const loadColumnVisibilityFromLocalStorage = () => {
    if (!props?.site?.id) {
      return;
    }
    const existingData = localStorage.getItem(
      `__assetList_visible_columns_${props?.site?.id}`
    )
      ? JSON.parse(
          localStorage.getItem(`__assetList_visible_columns_${props?.site?.id}`)
        )
      : {};

    console.log('column visibility', existingData);

    setVisibleColumns(existingData);

    return existingData;
  };

  const onColumnVisibilityModelChange = (newModel) => {
    localStorage.setItem(
      `__assetList_visible_columns_${props?.site?.id}`,
      JSON.stringify(newModel)
    );

    setVisibleColumns(newModel);
  };

  // const rows = [
  //   {
  //     id: 1,
  //     actions: "Edit",
  //     name_id: "Super E Motor",
  //     type: "Motor",
  //     location: "Room 1",
  //     notes: "leaks",
  //   },
  //   {
  //     id: 2,
  //     actions: "Edit",
  //     name_id: "Belt Drive",
  //     type: "Drive",
  //     location: "Room 1",
  //     notes: "Yes",
  //   },
  //   {
  //     id: 3,
  //     actions: "Edit",
  //     name_id: "Exhaust Fan",
  //     type: "Blower",
  //     location: "Room 1",
  //     notes: "Yes",
  //   },
  // ];

  return (
    <>
      {/* <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Add New Asset</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter asset name below.</DialogContentText>
          <TextField
            autoFocus
            fullWidth
            variant="standard"
            name="name"
            label="Asset Name"
            required={requiredFields.name}
            id="Asset Name"
            value={state.name ?? ''}
            onChange={handleFormChange}
            error={formErrors.name}
            helperText={formErrors.name}
            onBlur={validate}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button
            // sx={{ backgroundColor: '#519801', color: '#fff' }}
            onClick={submit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog> */}

      {/* This is the confirmation dialog for deleting assets */}

      <Dialog open={confirmDeleteOpen} onClose={handleDeleteDialog}>
        <DialogTitle>Delete Asset?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {assetToDelete?.name} will be deleted, are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialog}>Cancel</Button>
          <Button
            color="secondary"
            onClick={() => {
              confirmDeleteAsset();
            }}
          >
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Card sx={{ backgroundColor: '#F0F1F9' }}>
        <CardHeader
          title={'ASSET LIST'}
          sx={{ backgroundColor: '#6E7B82', color: '#FFF' }}
          action={
            !props.hideAddNewAssetButton && (
              <>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  sx={{ backgroundColor: '#519801' }}
                  onClick={createAsset}
                >
                  Add New Asset
                </Button>
              </>
            )
          }
        ></CardHeader>
        <Grid container>
          {/* <div style={{ width: '100%' }}> */}
          <DataGrid
            columnVisibilityModel={visibleColumns}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}
            initialState={{
              sorting: {
                sortModel: [{ field: 'updatedAt', sort: 'desc' }],
              },
            }}
            loading={props.loading}
            autoHeight
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[15, 25, 50]}
            disableSelectionOnClick
            // fullWidth
            rows={props.assets}
            columns={columns}
            components={{ NoRowsOverlay, Toolbar: GridToolbar }}
          />
          {/* </div> */}
        </Grid>
      </Card>
    </>
  );
}
