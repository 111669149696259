import { useState, useEffect } from 'react';
import { nullifyEmptyString } from '../../utilities';
import { Link as RouterLink } from 'react-router-dom';

import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Container,
  Button,
  FormControl,
  FormControlLabel,
  TextField,
  Grid,
  Checkbox,
  Box,
} from '@mui/material';

import API from '../../common/services/API';

import { useParams, useNavigate } from 'react-router-dom';

export default function EditUser() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formErrors, setFormErrors] = useState({});

  const requiredFields = {
    name: true,
    email: true,
  };

  const [details, setDetails] = useState({
    name: null,
    title: null,
    email: null,
    status: null,
  });

  const fetchData = async () => {
    const response = await API.get(`/users/${id}`);
    setDetails(response.data);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setDetails({ ...details, [name]: value });
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setDetails({ ...details, [name]: checked });
  };

  const validate = () => {
    const errors = {};
    for (const [field, required] of Object.entries(requiredFields)) {
      if (required && !details[field]) {
        errors[field] = 'this field is required.';
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return false;
    }

    setFormErrors({});
    return true;
  };

  useEffect(() => fetchData(), []);

  const submit = async () => {
    if (!validate()) {
      return;
    }
    try {
      const response = await API.put(
        `/users/${id}`,
        nullifyEmptyString(details)
      );

      const userid = response.data.id;
      navigate(`/users/${userid}`);
    } catch (err) {
      alert('something went wrong');
      console.error(err);
    }
  };

  return (
    <Container maxWidth="xl">
      <Card>
        <CardHeader
          title="EDIT USER"
          sx={{ backgroundColor: '#275295', color: '#FFF' }}
        ></CardHeader>
        <CardContent>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    name="name"
                    label="Name"
                    id="Name"
                    disabled="true"
                    required={requiredFields.name}
                    value={details.name ?? ''}
                    onChange={handleFormChange}
                    error={formErrors.name}
                    helperText={formErrors.name}
                    onBlur={validate}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    name="title"
                    label="Title"
                    id="Title"
                    required={requiredFields.title}
                    value={details.title ?? ''}
                    onChange={handleFormChange}
                    error={formErrors.title}
                    helperText={formErrors.title}
                    onBlur={validate}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    name="email"
                    label="Email"
                    id="Email"
                    disabled="true"
                    required={requiredFields.email}
                    value={details.email ?? ''}
                    onChange={handleFormChange}
                    error={formErrors.email}
                    helperText={formErrors.email}
                    onBlur={validate}
                  />
                </FormControl>
              </Grid>

              <Grid item>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="admin"
                        label="Admin"
                        id="Admin"
                        checked={details.admin ?? ''}
                        onChange={handleCheckboxChange}
                        error={formErrors.admin}
                        helperText={formErrors.admin}
                      />
                    }
                    label="Admin"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            size="medium"
            variant="text"
            component={RouterLink}
            to={`/users/${id}`}
          >
            CANCEL
          </Button>
          <Button
            size="medium"
            variant="contained"
            sx={{ backgroundColor: '#519801' }}
            onClick={submit}
          >
            UPDATE USER
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
}
