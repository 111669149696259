import { useState, useEffect } from 'react';
import { TreeView, TreeItem } from '@mui/lab';
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';

// import {Close as CloseIcon} from "@mui/icons-material";

export default function LocationTree(props) {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    // we want to get all of the top level parents
    const parents = props.locations.filter((location) => {
      return location.LocationId === null;
    });

    // loop over all of the top level parents
    // and return a new object which contains the children array
    const newParents = parents.map((parent) => {
      return buildParent(props.locations, parent);
    });

    setLocations(newParents);
  }, [props.locations]);

  const renderChildren = (parent) => {
    if (parent.children.length === 0) {
      return;
    }

    return (
      <>
        {parent.children.map((child) => (
          <div onContextMenu={(event) => props.handleContextMenu(event, child)}>
            <TreeItem key={child.id} nodeId={child.id} label={child.name}>
              {renderChildren(child)}
            </TreeItem>
          </div>
        ))}
      </>
    );
  };

  const buildParent = (locations, parent) => {
    // find all of the child locations for the passed parent
    const children = locations
      .filter((location) => {
        // if the current location's parent matches the parent passed into
        // this function
        return location.LocationId === parent.id;
      })
      .map((child) => {
        return buildParent(locations, child);
      });
    parent.children = children;
    return parent;
  };

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeSelect={props.onNodeSelect}
      sx={{
        flexGrow: 1,
      }}
    >
      {locations.map((location) => (
        <div
          onContextMenu={(event) => props.handleContextMenu(event, location)}
        >
          <TreeItem
            key={location.id}
            nodeId={location.id}
            label={location.name}
          >
            {renderChildren(location)}
          </TreeItem>
        </div>
      ))}
    </TreeView>
  );
}
