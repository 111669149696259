import {
  Card,
  CardHeader,
  Container,
  Button,
  Box,
  Grid,
  CardContent,
  CircularProgress,
  Stack,
} from '@mui/material';

import { Add } from '@mui/icons-material';
import SiteCard from './SiteCard';
import { useState, useEffect } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import API from '../../common/services/API';
import CardContentWithLoader from '../../common/components/CardContentWithLoader';

export default function SitesList() {
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(true);

  // fetch our sites from the API
  const fetchData = async () => {
    setLoading(true);
    const response = await API.get('/sites');
    setSites(response.data);
    setLoading(false);
  };

  // an effect that is run when the component loads for the first time, because
  // of the empty array of depdencies
  useEffect(() => fetchData(), []);

  return (
    <Container maxWidth="xl">
      <Card>
        <CardHeader
          title="SITE LIST"
          sx={{ backgroundColor: '#275295', color: '#FFF' }}
          action={
            <>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Button
                variant="contained"
                startIcon={<Add />}
                sx={{ backgroundColor: '#519801' }}
                component={RouterLink}
                to="/sites/create"
              >
                Add New Site
              </Button>
            </>
          }
        ></CardHeader>
        <CardContentWithLoader loading={loading}>
          <Grid container spacing={4} sx={{ p: 2 }}>
            {(sites.length > 0 &&
              sites.map((site) => <SiteCard site={site} />)) || (
              <Grid item textAlign="center" sx={{ flexGrow: 1 }}>
                There are no sites.
              </Grid>
            )}
          </Grid>
        </CardContentWithLoader>
      </Card>
    </Container>
  );
}
