import React, { useState, useEffect, createContext } from 'react';
import axios from 'axios';
import API from '../../common/services/API';
import User from '../models/User';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState();

  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkAuth = async () => {
    // setup our cross storage client for SSO
    // get the token and user from local storage
    let token = localStorage.getItem('__token');
    let user = localStorage.getItem('__user');

    if (token && user) {
      // due to the fact that users permissions can be changed, if the user is
      // already "logged in" we're going to refresh the user object from the
      // server with all of the latest permissions, but we're first going to
      // just log them in as normal, so they don't have to wait for the API call
      // to finish to start using the app, but then their user will be updated
      // and their permissions can change.
      setIsLoggedIn(true);
      setUser(new User(JSON.parse(user)));
      API.get(`/auth/refresh`)
        .then((results) => {
          loginViaToken(results.data.token, results.data.user);
        })
        .catch((err) => {
          console.error(err);
          // if there is an error with refreshing the user's credentials
          // we're going to make sure that the user is logged out instead
          // of potentially being "logged in" with no access
          logout();
        });
    }
  };

  const logout = async () => {
    localStorage.removeItem('__token');
    localStorage.removeItem('__user');
    setIsLoggedIn(false);
    setUser();
  };

  const loginViaToken = (token, user) => {
    localStorage.setItem('__token', token);
    localStorage.setItem('__user', JSON.stringify(user));
    setUser(new User(user));
    setIsLoggedIn(true);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        user,
        checkAuth,
        logout,
        loginViaToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
