import { useState, useEffect, useContext } from 'react';
import { Refresh } from '@mui/icons-material';
import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  Button,
  Grid,
  Box,
  Checkbox,
  FormControlLabel,
  FormControl,
  Autocomplete,
} from '@mui/material';

// import axios from "axios";

import { useParams } from 'react-router-dom';

import { AuthContext } from '../../auth/context/AuthContext';

export default function AssetFilters(props) {
  const { id } = useParams();
  const { user } = useContext(AuthContext);

  // this function handles the 'Has Photos' checkbox
  // this checkbox has three states, true, false, and indeterminate
  // the function sets value to true, then checks the hasPhotos filter state, true -> false, false -> null, null -> true
  const toggleHasPhotos = () => {
    let value = true;
    if (props.filters.hasPhotos === true) {
      value = false;
    } else if (props.filters.hasPhotos === false) {
      value = null;
    }
    props.setFilters({ ...props.filters, hasPhotos: value });
  };

  // this function handles the 'Completed' checkbox, identical to toggleHasPhotos func
  const toggleCompleted = () => {
    let value = true;
    if (props.filters.completed === true) {
      value = false;
    } else if (props.filters.completed === false) {
      value = null;
    }
    props.setFilters({ ...props.filters, completed: value });
  };

  // this function toggles showing deleted assets, the toggle is only rendered for admins and has option to restore assets
  const toggleDeletedAssets = () => {
    let value = !props.filters.deletedAt;
    props.setFilters({ ...props.filters, deletedAt: value });
  };

  // this function sets the filter state to the new value of whatever event.target is being changed, in this case only Name.
  const handleFormChange = (event) => {
    const { name, value } = event.target;

    props.setFilters({ ...props.filters, [name]: value });
  };

  return (
    <Card sx={{ backgroundColor: '#F0F1F9' }}>
      <CardHeader
        title="Filters"
        sx={{ backgroundColor: '#6E7B82', color: '#FFF' }}
        action={
          <>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Button
              variant="text"
              endIcon={<Refresh />}
              sx={{ color: '#fff' }}
              onClick={() => {
                props.resetFilters();
              }}
            >
              CLEAR FILTERS
            </Button>
          </>
        }
      ></CardHeader>
      <CardContent>
        <form noValidate autoComplete="off">
          <Grid container spacing={2} direction="column">
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  name="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={props.filters.name}
                  onChange={handleFormChange}
                />
              </FormControl>
            </Grid>
            {/* <Grid item>
              <FormControl fullWidth>
                <Autocomplete
                    name="Name"
                    label="Name/Id"
                    id="name"
                    value={names.Name}
                    onChange={(event, value) => {
                      setNames({
                        ...names,
                        Name: value,
                      });
                 
                    //  props.setFilters({...props.filters, name: names});
                    }}
                    options={names}
                    getOptionLabel={(option) => {
                      return option.name;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Name/Id" />
                    )}
                  />
              </FormControl>
            </Grid> */}

            <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Box sx={{ flexgrow: 1 }}> </Box>

              <FormControlLabel
                control={
                  <Checkbox
                    onChange={toggleHasPhotos}
                    indeterminate={props.filters.hasPhotos === null}
                    checked={!!props.filters.hasPhotos}
                  />
                }
                label="Has Photos"
              />
            </Grid>

            <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Box sx={{ flexGrow: 1 }}></Box>

              <FormControlLabel
                control={
                  <Checkbox
                    onChange={toggleCompleted}
                    indeterminate={props.filters.completed === null}
                    checked={!!props.filters.completed}
                  />
                }
                label="Completed"
              />
            </Grid>

            {user.admin === true && (
              <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ flexgrow: 1 }}> </Box>

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={toggleDeletedAssets}
                      checked={props.filters.deletedAt}
                    />
                  }
                  label="Toggle Deleted Assets"
                />
              </Grid>
            )}
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}
