import { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  Container,
  Grid,
  Button,
  FormControl,
  TextField,
  CardActions,
  CardContent,
  Autocomplete,
} from '@mui/material';

import API from '../../common/services/API';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { nullifyEmptyString } from '../../utilities';

export default function CreateWalkthrough() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    User: null,
    due_date: null,
    name: null,
  });
  const [formErrors, setFormErrors] = useState({});

  const [users, setUsers] = useState([]);

  const fetchData = async () => {
    const response = await API.get('/users');

    setUsers(response.data);
  };

  const requiredFields = {
    User: true,
    name: true,
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const validate = () => {
    const errors = {};
    for (const [field, required] of Object.entries(requiredFields)) {
      if (required && !state[field]) {
        errors[field] = 'This field is required';
      }
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return false;
    }
    setFormErrors({});
    return true;
  };

  useEffect(() => fetchData(), []);
  // console.log(users);
  // console.log("response", users[1].name);

  const submit = async () => {
    if (!validate()) {
      return;
    }
    try {
      const walkthroughObject = {
        due_date: state.due_date,
        UserId: state.User.id,
        SiteId: searchParams.get('SiteId'),
        status: null,
        name: state.name,
      };
      const response = await API.post(
        '/walkthroughs',
        nullifyEmptyString(walkthroughObject)
      );
      navigate(`/walkthroughs/${response.data.id}`);
    } catch (err) {
      alert('Something went wrong');
      console.error(err);
    }
  };

  return (
    <Container maxWidth="xl">
      <Card>
        <CardHeader
          title="ADD NEW WALKTHROUGH"
          sx={{ backgroundColor: '#275295', color: '#FFF' }}
        ></CardHeader>
        <CardContent>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    name="User"
                    label="Assigned User"
                    id="Assigned_User"
                    required={requiredFields.User}
                    value={state.User}
                    // onChange={handleFormChange}
                    onChange={(event, value) => {
                      setState({
                        ...state,
                        User: value,
                      });
                    }}
                    error={formErrors.User}
                    helperText={formErrors.User}
                    onBlur={validate}
                    options={users}
                    getOptionLabel={(option) => {
                      return option.name;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Assigned User" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Name"
                    variant="outlined"
                    name="name"
                    id="Name"
                    shrink="true"
                    required={requiredFields.name}
                    value={state.name ?? ''}
                    onChange={handleFormChange}
                    error={formErrors.name}
                    helperText={formErrors.name}
                    onBlur={validate}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Due Date"
                      value={state.due_date}
                      onChange={(newValue) => {
                        setState({ ...state, due_date: newValue });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            size="medium"
            variant="contained"
            sx={{ backgroundColor: '#519801' }}
            onClick={submit}
          >
            CREATE WALKTHROUGH
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
}
