import { useState, useContext } from 'react';
import {
  AppBar,
  Toolbar,
  Container,
  Typography,
  Box,
  Button,
  Tooltip,
  Menu,
  Grid,
  MenuItem,
} from '@mui/material';
import { Route, Routes, Link as RouterLink } from 'react-router-dom';

import Login from './auth/components/Login';
import UsersList from './users/components/UsersList';
import SitesList from './sites/components/SitesList';
import EditUser from './users/components/EditUser';
import CreateUser from './users/components/CreateUser';
import MyWalkthroughs from './walkthroughs/components/MyWalkthroughs';
import CreateSite from './sites/components/CreateSite';
import UpdateSite from './sites/components/UpdateSite';
// import UserProfile from './auth/components/UserProfile';
import SiteDetailsContainer from './sites/components/SiteDetailsContainer';
import EditWalkthrough from './walkthroughs/components/EditWalkthrough';
import CreateWalkthrough from './walkthroughs/components/CreateWalkthrough';
import UserDetails from './users/components/UserDetails';
import Walkthroughs from './walkthroughs/components/Walkthroughs';
import { AuthProvider, AuthContext } from './auth/context/AuthContext';
import PrivateRoute from './auth/components/PrivateRoute';
import AssetShare from './assets/components/AssetShare';
import { SnackbarProvider } from 'material-ui-snackbar-provider';
import CustomSnackbar from './common/components/CustomSnackbar';

function App() {
  const [menuAnchorElement, setMenuAnchorElement] = useState(null);
  const { isLoggedIn, user, logout } = useContext(AuthContext);
  console.log('user', user);

  const openMenu = (event) => {
    setMenuAnchorElement(event.target);
  };

  const closeMenu = () => {
    setMenuAnchorElement(null);
  };

  return (
    <div className="App">
      {isLoggedIn && (
        <AppBar position="static" sx={{ backgroundColor: '#1B2134' }}>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Typography
                component={RouterLink}
                // App Title: on click if the user is logged in and an admin they are directed to the site list, else goes to my-walkthroughs
                to={
                  (isLoggedIn && user.admin === true && '/sites') ||
                  '/my-walkthroughs'
                }
                sx={{ color: '#FFFFFF', textDecoration: 'none' }}
                variant="h6"
                noWrap
                // component="div"
              >
                Current State Analysis
              </Typography>
              <Box sx={{ flexGrow: 1 }}></Box>
              {/* wrap in a hamburger button from here */}
              {/* if user is logged in and an admin show user and site list buttons, else button hide */}
              {isLoggedIn && user.admin === true && (
                <>
                  <Button
                    size="large"
                    variant="text"
                    sx={{ color: '#FFFFFF' }}
                    component={RouterLink}
                    to="/users"
                  >
                    Users
                  </Button>
                  <Button
                    size="large"
                    variant="text"
                    sx={{ color: '#FFFFFF' }}
                    component={RouterLink}
                    to="/sites"
                  >
                    Sites
                  </Button>
                </>
              )}
              <Button
                size="large"
                variant="text"
                sx={{ color: '#FFFFFF' }}
                component={RouterLink}
                to="/my-walkthroughs"
              >
                My Walkthroughs
              </Button>
              {/* to here */}
              {/* if user is logged in show 'settings' menu on click*/}
              {isLoggedIn === true && (
                <Tooltip title="Open Settings">
                  <Button
                    variant="outlined"
                    onClick={openMenu}
                    sx={{
                      color: '#FFFFFF',
                      border: '1px solid rgba(255,255,255,0.4)',
                    }}
                  >
                    {user.name}
                  </Button>
                </Tooltip>
              )}
              <Menu
                anchorEl={menuAnchorElement}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                open={menuAnchorElement !== null}
                onClose={closeMenu}
                sx={{ mt: 1 }}
              >
                {/* <MenuItem component={RouterLink} to="/profile">
                Profile
              </MenuItem> */}
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </Toolbar>
          </Container>
        </AppBar>
      )}
      <Grid mt={4} mb={4}>
        {/* main part of the app with routes */}
        <SnackbarProvider
          SnackbarComponent={CustomSnackbar}
          SnackbarProps={{
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }}
        >
          <main>
            <Routes>
              <Route path="/login" element={<Login />} />

              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <MyWalkthroughs />
                  </PrivateRoute>
                }
              />

              <Route exact path="/share/asset/:id" element={<AssetShare />} />

              <Route
                exact
                path="/users"
                element={
                  <PrivateRoute admin={true}>
                    <UsersList />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/users/:id/edit"
                element={
                  <PrivateRoute admin={true}>
                    <EditUser />
                  </PrivateRoute>
                }
              />
              {/* <Route
              exact
              path="/profile"
              element={
                <PrivateRoute>
                  <UserProfile />
                </PrivateRoute>
              }
            /> */}
              <Route
                exact
                path="/users/create"
                element={
                  <PrivateRoute admin={true}>
                    <CreateUser />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/users/:id"
                element={
                  <PrivateRoute admin={true}>
                    <UserDetails />
                  </PrivateRoute>
                }
              />

              <Route
                exact
                path="/sites"
                element={
                  <PrivateRoute admin={true}>
                    <SitesList />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/sites/:id/edit"
                element={
                  <PrivateRoute admin={true}>
                    <UpdateSite />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/sites/:id"
                element={
                  <PrivateRoute admin={true}>
                    <SiteDetailsContainer />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/sites/create"
                element={
                  <PrivateRoute admin={true}>
                    <CreateSite />
                  </PrivateRoute>
                }
              />

              <Route
                exact
                path="/my-walkthroughs"
                element={
                  <PrivateRoute>
                    <MyWalkthroughs />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/walkthroughs/:id"
                element={
                  <PrivateRoute>
                    <Walkthroughs />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/walkthroughs/:id/edit"
                element={
                  <PrivateRoute admin={true}>
                    <EditWalkthrough />
                  </PrivateRoute>
                }
              />

              <Route
                exact
                path="/walkthroughs/create"
                element={
                  <PrivateRoute admin={true}>
                    <CreateWalkthrough />
                  </PrivateRoute>
                }
              />
            </Routes>
          </main>
        </SnackbarProvider>
      </Grid>
    </div>
  );
}

export default () => {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
};
