import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'material-ui-snackbar-provider';

import API from '../../common/services/API';
import {
  TextField,
  Card,
  CardHeader,
  Container,
  CardContent,
  Button,
  Grid,
  FormControl,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import qs from 'qs';
import config from '../../common/config';

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const snackbar = useSnackbar();
  const { isLoggedIn, user, loginViaToken } = useContext(AuthContext);
  const [formEnabled, setFormEnabled] = useState(true);

  const redirectOnLogin = () => {
    if (isLoggedIn) {
      // if we could potentially be opening the popup
      const queryParams = new URLSearchParams(location.search);
      const objectParams = qs.parse(Object.fromEntries(queryParams));
      if (location.state && location.state.hasOwnProperty('from')) {
        return navigate(location.state.from);
      } else if (objectParams.returnTo) {
        return navigate(objectParams.returnTo);
      }

      if (user.admin === true) {
        return navigate('/sites');
      }

      return navigate('/my-walkthroughs');
    }
  };

  const loginViaOauth = () => {
    let path = config.oauth.path;
    if (location.state && location.state.hasOwnProperty('from')) {
      path += `?returnTo=${location.state.from.pathname}`;
      if (location.state.from.search) {
        path += `${location.state.from.search}`;
      }
    }
    window.location = path;
  };

  // if the user is logged in when this component loads, make sure to redirect
  // them back to the dashboard
  useEffect(() => {
    // I believe that due to an issue with the speed of local storage storing
    // the user and token information we need to "sleep" for a period of time
    // here so that the redirects won't send the user straight back to the
    // homepage when they have an intended location
    setTimeout(redirectOnLogin, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  // // if the token and user are provided as query params, the user is being logged
  // // in via oauth
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const objectParams = qs.parse(Object.fromEntries(queryParams));
    if (isLoggedIn) {
      return;
    }

    if (objectParams.token && objectParams.user) {
      // login via the token and let the effect of logging in redirect
      // the user to the correct location
      return loginViaToken(objectParams.token, objectParams.user);
      // after the user clicks the link in the login
    } else if (objectParams['login-token']) {
      // hit the next end point and check the token exists and isnt expired
      // get the user from the shit, get a plain object and use that on a jwt
      // send the user back to the login page again with a user object and token to log them in forrealzies
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [formData, setFormData] = useState({ email: null });
  const [formErrors, setFormErrors] = useState({});
  const requiredFields = { email: true };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const errors = {};
    for (const [field, required] of Object.entries(requiredFields)) {
      if (required && !formData[field]) {
        errors[field] = 'this field is required.';
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return false;
    }

    setFormErrors({});
    return true;
  };

  const submit = async () => {
    if (!validate()) {
      return;
    }
    try {
      // add a pop up toast that an email will be sent
      setFormEnabled(false);
      const response = await API.post(`/auth/token-request`, {
        email: formData.email,
      });
      snackbar.showMessage('An email with a login link will be sent shortly.');
    } catch (err) {
      alert('something went wrong');
      setFormEnabled(true);
      console.error(err);
    }
  };

  const CenteredBox = ({ children }) => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        {children}
      </Box>
    );
  };

  return (
    <Container maxWidth="sm">
      <Card>
        <CardHeader
          title="LOGIN TO CURRENT STATE ANALYSIS"
          sx={{
            backgroundColor: '#275295',
            color: '#FFF',
            textAlign: 'center',
          }}
        ></CardHeader>

        <CardContent>
          {(formEnabled && (
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="Email"
                      variant="outlined"
                      name="email"
                      id="email"
                      shrink="true"
                      disabled={!formEnabled}
                      required={requiredFields.email}
                      value={formData.email ?? ''}
                      onChange={handleFormChange}
                      error={formErrors.email}
                      helperText={formErrors.email}
                      onBlur={validate}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    size="medium"
                    variant="contained"
                    disabled={!formEnabled}
                    sx={{
                      backgroundColor: '#519801',
                      '&:hover': {
                        backgroundColor: '#458300',
                      },
                    }}
                    onClick={() => submit()}
                  >
                    GET EMAIL LINK
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <CenteredBox>
                    <Divider style={{ width: '50%' }} />
                    <Typography>or</Typography>
                    <Divider style={{ width: '50%' }} />
                  </CenteredBox>
                </Grid>
                <Grid item xs={12}>
                  <CenteredBox>
                    <Button
                      disabled={!formEnabled}
                      size="medium"
                      variant="contained"
                      onClick={() => loginViaOauth()}
                    >
                      LOGIN WITH MICROSOFT
                    </Button>
                  </CenteredBox>
                </Grid>
              </Grid>
            </form>
          )) || (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  A login link has been sent to your email and should arrive
                  shortly. If an email does not arrive in a minute click the
                  button below to retry.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <CenteredBox>
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={() => setFormEnabled(true)}
                  >
                    RETRY
                  </Button>
                </CenteredBox>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Container>
  );
}
