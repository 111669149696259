import { useState } from 'react';
import { nullifyEmptyString } from '../../utilities';
import { Link as RouterLink } from 'react-router-dom';
import API from '../../common/services/API';
import { useNavigate } from 'react-router-dom';

import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Container,
  Button,
  FormControl,
  FormControlLabel,
  TextField,
  Grid,
  Checkbox,
} from '@mui/material';

export default function CreateUser() {
  const navigate = useNavigate();

  const [formErrors, setFormErrors] = useState({});

  const requiredFields = {
    name: true,
    email: true,
  };

  const [userDetails, setUserDetails] = useState({
    name: null,
    title: null,
    email: null,
    status: null,
    oid: null,
  });

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setUserDetails({ ...userDetails, [name]: value });
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setUserDetails({ ...userDetails, [name]: checked });
  };

  const validate = () => {
    const errors = {};
    for (const [field, required] of Object.entries(requiredFields)) {
      if (required && !userDetails[field]) {
        errors[field] = 'this field is required.';
      }
      // if the field is 'email' check that it matches email format
      if (requiredFields && !validateEmail(userDetails['email'])) {
        console.log('email true');
        errors['email'] = 'this field must be an email.';
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      console.log({ formErrors });
      return false;
    }

    setFormErrors({});
    return true;
  };

  const validateEmail = (field) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(field);
  };

  const submit = async () => {
    if (!validate()) {
      return;
    }
    try {
      const response = await API.post(
        `/users`,
        nullifyEmptyString(userDetails)
      );
      const userId = response.data.id;
      const userEmail = response.data.email;
      await API.post(`/auth/token-request?new-user=true`, {
        email: userEmail,
      });
      navigate(`/users/${userId}`);
    } catch (err) {
      alert('something went wrong');
      console.error(err);
    }
  };

  return (
    <Container maxWidth="xl">
      <Card>
        <CardHeader
          title="CREATE USER"
          sx={{ backgroundColor: '#275295', color: '#FFF' }}
        ></CardHeader>
        <CardContent>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    name="name"
                    label="Name"
                    id="Name"
                    required={requiredFields.name}
                    value={userDetails.name ?? ''}
                    onChange={handleFormChange}
                    error={formErrors.name}
                    helperText={formErrors.name}
                    onBlur={validate}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    name="title"
                    label="Title"
                    id="Title"
                    required={requiredFields.title}
                    value={userDetails.title ?? ''}
                    onChange={handleFormChange}
                    error={formErrors.title}
                    helperText={formErrors.title}
                    onBlur={validate}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    name="email"
                    label="Email"
                    id="Email"
                    type="email"
                    required={requiredFields.email}
                    value={userDetails.email ?? ''}
                    onChange={handleFormChange}
                    error={formErrors.email}
                    helperText={formErrors.email}
                    onBlur={validate}
                  />
                </FormControl>
              </Grid>

              <Grid item>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="admin"
                        label="Admin"
                        id="Admin"
                        checked={userDetails.admin ?? ''}
                        onChange={handleCheckboxChange}
                        error={formErrors.admin}
                        helperText={formErrors.admin}
                      />
                    }
                    label="Admin"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </CardContent>

        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            size="medium"
            variant="text"
            component={RouterLink}
            to="/users"
          >
            CANCEL
          </Button>
          <Button
            size="medium"
            variant="contained"
            sx={{ backgroundColor: '#519801' }}
            onClick={submit}
          >
            CREATE
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
}
