import { useState } from 'react';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Container,
  Grid,
  Button,
  FormControl,
  TextField,
} from '@mui/material';

import API from '../../common/services/API';

import { useNavigate } from 'react-router-dom';
import { nullifyEmptyString } from '../../utilities';

import { Link as RouterLink } from 'react-router-dom';

export default function CreateSite() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    name: '',
    description: '',
    contact_name: '',
    contact_email: '',
    contact_phone: '',
  });

  const [formErrors, setFormErrors] = useState({});

  const requiredFields = {
    name: true,
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    // the [name] allows us to use a variable as a property name
    setState({ ...state, [name]: value });
  };

  const validate = () => {
    // check for required fields
    const errors = {};
    // Object.entries allows us to loop over an object as if it were an array
    // and we can get the key and value
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
    for (const [field, required] of Object.entries(requiredFields)) {
      if (required && !state[field]) {
        // then there is an error
        errors[field] = 'This field is required.';
      }
    }

    // check if there were any errors
    // we use the Object.keys to get all of the keys from the object as an array
    // then we check the length to determine if there were any errors
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return false;
    }

    // reset the form errors to an empty object if there are no errors
    setFormErrors({});
    return true;
  };

  const submit = async () => {
    if (!validate()) {
      return;
    }

    // using a try catch with async/await promises
    try {
      const response = await API.post('/sites', nullifyEmptyString(state));
      console.log(response);
      const id = response.data.id;
      // if successful
      navigate(`/sites/${id}`);
    } catch (err) {
      // send a message to the user that something went wrong
      // TODO: replace this a snackbar or something
      alert('Something went wrong');
      console.error(err);
    }

    // if we want to use traditional promises with the then/catch functions
    // API
    //   .post('/sites', state)
    //   .then((response) => {
    //     // if successful
    //     console.log(response);
    //   })
    //   .catch((err) => {
    //     // send a message to the user that something went wrong
    //     alert('Something went wrong');
    //     console.error(err);
    //   });
  };

  return (
    <Container maxWidth="xl">
      <Card>
        <CardHeader
          title="ADD NEW SITE"
          sx={{ backgroundColor: '#275295', color: '#FFF' }}
        ></CardHeader>
        <CardContent>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    name="name"
                    label="Name"
                    required={requiredFields.name}
                    id="Name"
                    value={state.name ?? ''}
                    onChange={handleFormChange}
                    error={formErrors.name}
                    helperText={formErrors.name}
                    onBlur={validate}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    name="description"
                    required={requiredFields.description}
                    label="Description"
                    id="Description"
                    value={state.description}
                    onChange={handleFormChange}
                    error={formErrors.description}
                    helperText={formErrors.description}
                    onBlur={validate}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <fieldset
                  style={{
                    borderRadius: 6,
                  }}
                >
                  <legend
                    style={{
                      backgroundColor: '#FFF',
                      padding: 10,
                      borderRadius: 6,
                    }}
                  >
                    Site Contact Info
                  </legend>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          name="contact_name"
                          required={requiredFields.contact_name}
                          label="Contact Name"
                          id="ContactName"
                          value={state.contact_name}
                          error={formErrors.contact_name}
                          helperText={formErrors.contact_name}
                          onChange={handleFormChange}
                          onBlur={validate}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          name="contact_email"
                          required={requiredFields.contact_email}
                          label="Email"
                          id="Email"
                          value={state.contact_email}
                          error={formErrors.contact_email}
                          helperText={formErrors.contact_email}
                          onChange={handleFormChange}
                          onBlur={validate}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          name="contact_phone"
                          required={requiredFields.contact_phone}
                          label="Phone Number"
                          id="PhoneNumber"
                          value={state.contact_phone}
                          error={formErrors.contact_phone}
                          helperText={formErrors.contact_phone}
                          onChange={handleFormChange}
                          onBlur={validate}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </fieldset>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            size="medium"
            variant="text"
            component={RouterLink}
            to="/sites"
          >
            CANCEL
          </Button>
          <Button
            size="medium"
            variant="contained"
            sx={{ backgroundColor: '#519801' }}
            onClick={submit}
          >
            CREATE SITE
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
}
