import {
  Card,
  CardHeader,
  Button,
  Grid,
  CardActions,
  LinearProgress,
  CardContent,
  Typography,
  FormLabel,
} from '@mui/material';
import { Edit } from '@mui/icons-material';

import { Link as RouterLink } from 'react-router-dom';

export default function SiteCard(props) {
  return (
    <Grid item xs={12} md={6}>
      <Card elevation="3" sx={{ backgroundColor: '#F0F1F9' }}>
        <CardHeader
          sx={{ backgroundColor: '#6E7B82', color: '#FFF' }}
          title={props.site.name}
          component={RouterLink}
          to={`/sites/${props.site.id}`}
        ></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormLabel>Contact Name</FormLabel>

              <Typography component="div">
                {props.site.contact_name ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel>Phone Number</FormLabel>
              <Typography component="div">
                {props.site.contact_phone ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Description</FormLabel>

              <Typography component="div">
                {props.site.description ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Last Updated</FormLabel>

              <Typography component="div">
                {props.site.lastUpdated
                  ? new Date(props.site.lastUpdated).toLocaleString([], {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormLabel>Assets</FormLabel>

              <Typography variant="h5" component="div">
                {props.site.assetsCount ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormLabel>Walkthroughs</FormLabel>
              <Typography variant="h5" component="div">
                {props.site.walkthroughsCount ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Progress</FormLabel>
              <LinearProgress
                sx={{ height: 10 }}
                variant="determinate"
                value={props.site.progress || 0}
              />
              <Typography variant="body2" color="text.secondary">
                {Math.round(props.site.progress || 0)}%
              </Typography>
            </Grid>
          </Grid>
        </CardContent>

        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            size="small"
            variant="text"
            startIcon={<Edit />}
            component={RouterLink}
            to={`/sites/${props.site.id}/edit`}
          >
            Edit Site
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}
