import { Card, CardHeader, Container, Button, Box, Grid } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Add, Edit, Visibility } from '@mui/icons-material';

import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import API from '../../common/services/API';

export default function UsersList() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    const response = await API.get('/users');
    setUsers(response.data);
    setLoading(false);
  };

  useEffect(() => fetchData(), []);

  const columns = [
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 0.2,
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={<Edit />}
          component={RouterLink}
          to={`/users/${row.id}/edit`}
        />,
        <GridActionsCellItem
          icon={<Visibility />}
          component={RouterLink}
          to={`/users/${row.id}`}
        />,
      ],
    },
    {
      field: 'name',
      headerName: 'Name',
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'title',
      headerName: 'Title',
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'admin',
      type: 'boolean',
      headerName: 'Admin Status',
      flex: 1,
      disableColumnMenu: true,
    },
  ];

  return (
    <Container maxWidth="xl">
      <Card>
        <CardHeader
          title="USER LIST"
          sx={{ backgroundColor: '#275295', color: '#FFF' }}
          action={
            <>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Button
                variant="contained"
                startIcon={<Add />}
                sx={{ backgroundColor: '#519801' }}
                component={RouterLink}
                to="/users/create"
              >
                Create New User
              </Button>
            </>
          }
        ></CardHeader>
        <Grid container>
          <DataGrid
            loading={loading}
            autoHeight
            rows={users}
            columns={columns}
            disableSelectionOnClick
          />
        </Grid>
      </Card>
    </Container>
  );
}
