export function nullifyEmptyString(data) {
  const newData = {};
  for (const property in data) {
    //   console.log(property, data[property]);
    newData[property] = data[property] === '' ? null : data[property];
    // alternative approach to ternary
    // if (data[property] === "") {
    //   newData[property] = null;
    // } else {
    //   newData[property] = data[property];
    // }
  }
  return newData;
}
