import { CardContent, CircularProgress } from '@mui/material';

export default function CardContentWithLoader(props) {
  return (
    <CardContent sx={{ position: 'relative', minHeight: '50px' }} {...props}>
      {props.loading && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            zIndex: '1200',
            marginTop: '-16px',
            marginLeft: '-16px',
            backgroundColor: 'rgba(255,255,255,.8)',
          }}
        >
          <CircularProgress />
        </div>
      )}
      {props.children}
    </CardContent>
  );
}
