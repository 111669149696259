import { useEffect, useState, useContext } from 'react';
import API from '../../common/services/API';

import {
  Grid,
  Container,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Alert,
  Typography,
  Tooltip,
} from '@mui/material';

import {
  Edit,
  Download,
  Visibility,
  FolderZip,
  PictureAsPdf,
  Description,
  Html,
} from '@mui/icons-material';

import { Link as RouterLink, useParams } from 'react-router-dom';
import { AuthContext } from '../../auth/context/AuthContext';

import AssetLocations from '../../assets/components/AssetLocations.js';
import AssetFilters from '../../assets/components/AssetFilters';
import PhotoGallery from '../../walkthroughs/components/PhotoGallery';
import AssetList from '../../assets/components/AssetList';
import AssetForm from '../../assets/components/AssetForm';
import fileDownload from 'js-file-download';

export default function Walkthroughs() {
  const { isLoggedIn, user } = useContext(AuthContext);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  // add all default states here name, type, etc
  const defaultFilters = {
    completed: null,
    name: '',
    location: null,
    hasPhotos: null,
    deletedAt: false,
  };
  // when the component loads
  const [filters, setFilters] = useState(defaultFilters);

  const [committedFilters, setCommittedFilters] = useState({});

  const [locations, setLocations] = useState([]);
  const [walkthroughs, setWalkthroughs] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [filterTimeout, setFilterTimeout] = useState();
  const [assets, setAssets] = useState([]);
  const [title, setTitle] = useState('-');
  const [siteId, setSiteId] = useState();

  const [fields, setFields] = useState([]);

  const [formData, setFormData] = useState({
    completed: null,
    name: '',
    location: '',
    notes: '',
    alternate_location: '',
    Walkthrough: null,
  });
  const [customFormData, setCustomFormData] = useState({});

  const [walkthrough, setWalkthrough] = useState(null);

  const hideButton = true;

  const resetFilters = () => {
    setFilters(defaultFilters);
  };

  const clearSelectedAsset = () => {
    setSelectedAsset(null);
    console.log('assets cleared');
    setCustomFormData({});
  };

  const fetchData = async () => {
    setLoading(true);
    // 1. get the walkthrough details (/api/walkthroughs/:id)
    const response = await API.get(`/walkthroughs/${id}`);
    const walkthrough = response.data;
    setWalkthrough(walkthrough);
    setFields(response.data.Site.Fields);
    setTitle(
      <>
        <Grid
          item
          component={RouterLink}
          to={
            (isLoggedIn &&
              user.admin === true &&
              `/sites/${response.data.Site.id}`) ||
            ''
          }
          sx={{ color: '#fff' }}
        >
          {response.data.Site.name}
        </Grid>

        <Grid item>
          {'\xa0'}
          {(walkthrough.name && ` > ${walkthrough.name} > `) ||
            ' > Walkthrough > '}

          {response.data.User.name}
        </Grid>
      </>
    );
    setSiteId(response.data.Site.id);

    // 2. get the walkthrough's site locations (/api/sites/:id/locations)
    const locationsResponse = await API.get(
      `/sites/${walkthrough.SiteId}/locations`
    );

    const locations = locationsResponse.data;
    setLocations(locations);

    if (user.admin === true) {
      const walkthroughsResponse = await API.get(
        `/sites/${walkthrough.SiteId}/walkthroughs`
      );

      const walkthroughs = walkthroughsResponse.data;
      setWalkthroughs(walkthroughs);
    }

    // 3. get the walkthrough's assets (/api/walkthroughs/:id/assets)
    // 4. ensure that the assets have the photos

    setLoading(false);
    console.log('data fetched');
  };

  useEffect(() => fetchData(), []);

  // when the assets change (when the fetchAssets is called)
  useEffect(() => {
    // if there is a selected asset, and check it has an id just to be sure
    if (selectedAsset && selectedAsset.id !== null) {
      // find the asset from the newly refreshed asset list
      const currentAsset = assets.find((a) => a.id === selectedAsset.id);
      if (currentAsset) {
        setSelectedAsset({ ...formData, Photos: currentAsset.Photos });
      }
    }
  }, [assets]);

  // a use effect hook, that is going to look for changes in our filter state
  // and commit them to our commitedFilters using the debounce, which is going to
  // call our fetchData function
  useEffect(() => {
    clearTimeout(filterTimeout);

    setFilterTimeout(
      setTimeout(() => {
        setCommittedFilters({ ...filters });
      }, 300)
    );
  }, [filters]);

  const exportWalkthrough = async () => {
    const response = await API.download(`/walkthroughs/${id}/export`);
    const date = new Date().toJSON().slice(0, 10);
    const filename = `${walkthrough.Site.name} - ${walkthrough.User.name}-walkthrough-${date}.xlsx`;
    fileDownload(response.data, filename);
  };

  const exportWalkthroughAndPhotos = async () => {
    const response = await API.get(`/walkthroughs/${id}/full-export`);
    alert(
      'You should receive an email with your zip export download link once it is finished processing.'
    );
  };

  const exportPDF = async () => {
    const response = await API.get(`/walkthroughs/${id}/pdf`);
    alert(
      'You should receive an email with your pdf export download link once it is finished processing.'
    );
  };

  const exportHTML = async () => {
    const response = await API.get(`/walkthroughs/${id}/pdf?format=html`);
    alert(
      'You should receive an email with your html export download link once it is finished processing.'
    );
  };

  const exportDocx = async () => {
    const response = await API.get(`/walkthroughs/${id}/docx`);
    alert(
      'You should receive an email with your docx export download link once it is finished processing.'
    );
  };

  // useEffect(() => setTitle, title);

  const fetchAssets = async () => {
    setLoading(true);
    // removed params: filters in place of just filters because of how permitjs works
    const assetResponse = await API.get(`/walkthroughs/${id}/assets`, filters);
    setAssets(assetResponse.data);
    setLoading(false);
  };

  const selectAsset = (asset, existingMetaData = {}) => {
    setSelectedAsset(asset);
    const assetMeta = {};
    // when an asset is selected map over the assets AssetMetas
    asset.AssetMeta.forEach((meta) => {
      const foundField = fields.find((field) => {
        return field.id === meta.FieldId;
      });
      if (!foundField) {
        return;
      }
      // data manipulations based on types, etc, can be done here
      let value = meta.value;
      if (foundField.type === 'boolean') {
        value = meta.value === '1' ? true : false;
      }
      if (foundField.multiple === true) {
        if (!assetMeta[meta.FieldId]) {
          assetMeta[meta.FieldId] = [];
        }
        assetMeta[meta.FieldId].push(value);
      } else {
        // if there is only one value set it to the value

        assetMeta[meta.FieldId] = value;
      }
    });

    setCustomFormData({ ...assetMeta, ...existingMetaData });
  };

  // when committed filters change re-query the assets
  useEffect(fetchAssets, [committedFilters]);

  const header = (
    <>
      <Grid container xs={12} alignItems="center" spacing={2}>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          {title}
        </Grid>

        <Grid
          item
          style={{ flexGrow: '1' }}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          {user.admin === true && (
            <Button
              variant="contained"
              startIcon={<Edit />}
              sx={{ backgroundColor: '#519801' }}
              component={RouterLink}
              to={`/walkthroughs/${id}/edit`}
            >
              EDIT WALKTHROUGH
            </Button>
          )}
          <Tooltip title="Export data as .xlsx">
            <IconButton onClick={exportWalkthrough} sx={{ color: '#fff' }}>
              <Download />
            </IconButton>
          </Tooltip>
          <Tooltip title="Export .zip with Photos">
            <IconButton
              onClick={exportWalkthroughAndPhotos}
              sx={{ color: '#fff' }}
            >
              <FolderZip />
            </IconButton>
          </Tooltip>
          {/* HIDE THIS */}
          {!hideButton && (
            <Tooltip title="Export PDF">
              <IconButton onClick={exportPDF} sx={{ color: '#fff' }}>
                <PictureAsPdf />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Export HTML">
            <IconButton onClick={exportHTML} sx={{ color: '#fff' }}>
              <Html />
            </IconButton>
          </Tooltip>
          {/* HIDE THIS */}
          {!hideButton && (
            <Tooltip title="Export .docx">
              <IconButton onClick={exportDocx} sx={{ color: '#fff' }}>
                <Description />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </>
  );

  return (
    <Container maxWidth="xxl" disableGutters>
      <Card sx={{ borderRadius: 0 }}>
        <CardHeader
          title={header}
          sx={{
            backgroundColor: '#275295',
            color: '#FFF',
          }}
          // action={
          //   <>
          //     <Box sx={{ flexGrow: 1 }}></Box>

          //     {user.admin === true && (
          //       <Button
          //         variant="contained"
          //         startIcon={<Edit />}
          //         sx={{ backgroundColor: '#519801' }}
          //         component={RouterLink}
          //         to={`/walkthroughs/${id}/edit`}
          //       >
          //         EDIT WALKTHROUGH
          //       </Button>
          //     )}

          //     <IconButton onClick={exportWalkthrough} sx={{ color: '#fff' }}>
          //       <Download />
          //     </IconButton>
          //   </>
          // }
        ></CardHeader>

        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12} md={6}>
            <AssetLocations
              fetchData={fetchData}
              loading={loading}
              siteId={siteId}
              locations={locations}
              filters={filters}
              setFilters={setFilters}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AssetFilters
              site={walkthrough?.Site}
              filters={filters}
              setFilters={setFilters}
              resetFilters={resetFilters}
            />
          </Grid>
          {/* we are loading the same component twice, because we have a state issue where
          the selectAsset function does not have the fields yet, and rendering this component twice
          allows us to show the loading indicator on the table but also still function.
          We tried to just update the key with JSON.stringify(fields), which does work however
          the scrolling when you select an asset is broken
           */}
          {loading ? (
            <Grid item xs={12}>
              <AssetList
                key="before-load"
                site={walkthrough?.Site}
                selectAsset={selectAsset}
                defaultFilters={defaultFilters}
                filters={filters}
                fetchData={fetchData}
                loading={loading}
                selectedAsset={selectedAsset}
                clearSelectedAsset={clearSelectedAsset}
                setSelectedAsset={setSelectedAsset}
                assets={assets}
                fetchAssets={fetchAssets}
                formData={formData}
                setFormData={setFormData}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <AssetList
                key="after-load"
                site={walkthrough?.Site}
                selectAsset={selectAsset}
                defaultFilters={defaultFilters}
                filters={filters}
                fetchData={fetchData}
                loading={loading}
                selectedAsset={selectedAsset}
                clearSelectedAsset={clearSelectedAsset}
                setSelectedAsset={setSelectedAsset}
                assets={assets}
                fetchAssets={fetchAssets}
                formData={formData}
                setFormData={setFormData}
              />
            </Grid>
          )}
          {selectedAsset === null && (
            <>
              <Grid item xs={12}>
                <Alert severity="info">
                  <div>
                    To view asset photos and asset form please click
                    <Visibility
                      style={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: -8,
                      }}
                    />
                    on an asset above.
                  </div>
                </Alert>
              </Grid>
            </>
          )}
          {selectedAsset !== null && (
            <>
              <Grid item xs={12} md={8}>
                <PhotoGallery
                  filters={filters}
                  loading={loading}
                  selectedAsset={selectedAsset}
                  fetchData={fetchData}
                  fetchAssets={fetchAssets}
                  assets={assets}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <AssetForm
                  walkthroughs={walkthroughs}
                  site={walkthrough?.Site}
                  loading={loading}
                  selectedAsset={selectedAsset}
                  clearSelectedAsset={clearSelectedAsset}
                  fetchData={fetchData}
                  fetchAssets={fetchAssets}
                  formData={formData}
                  setFormData={setFormData}
                  customFormData={customFormData}
                  setCustomFormData={setCustomFormData}
                  locations={locations}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Card>
    </Container>
  );
}
