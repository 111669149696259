import {
  Card,
  CardContent,
  CardHeader,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Container,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  FormHelperText,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Close as CloseIcon } from '@mui/icons-material';
import API from '../../common/services/API';
import { styled } from '@mui/material/styles';
import { useNavigate, createSearchParams } from 'react-router-dom';
import CardContentWithLoader from '../../common/components/CardContentWithLoader';

export default function AssetShare() {
  const [asset, setAsset] = useState();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchParams.get('currentPhoto')) {
      setCurrentPhoto(searchParams.get('currentPhoto'));
    } else {
      setCurrentPhoto(null);
    }
  }, [searchParams]);

  const fetchData = async () => {
    setLoading(true);
    const response = await API.get(`/assets/${id}`, {
      token: searchParams.get('token'),
    });
    console.log(response);
    setAsset(response.data);
    setLoading(false);
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const exitFullscreen = () => {
    const params = createSearchParams({
      token: searchParams.get('token'),
    });
    navigate({
      search: `?${params}`,
    });
  };

  const openFullscreen = (id) => {
    // setCurrentPhoto(photo);
    const params = createSearchParams({
      currentPhoto: id,
      token: searchParams.get('token'),
    });
    navigate({
      search: `?${params}`,
    });
  };

  const Subheader = () => {
    if (!asset) {
      return <></>;
    }

    let subheader = asset.LocationTree;

    if (asset.alternate_location) {
      subheader += ` (${asset.alternate_location})`;
    }

    return <>{subheader}</>;
  };

  return (
    <Container maxWidth="xl">
      <Card>
        <CardHeader title={asset?.name} subheader={<Subheader />} />
        <CardContentWithLoader loading={loading}>
          <Grid container spacing={2}>
            {asset?.notes && (
              <Grid item xs={12}>
                {asset.notes}
              </Grid>
            )}
            <Grid item xs={12}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    {asset?.Meta?.map((row) => (
                      <StyledTableRow
                        key={row.name}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ fontWeight: 'bold' }}
                        >
                          {row.label}
                        </TableCell>
                        <TableCell align="right">{row.value}</TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid container spacing={2} item xs={12}>
              <Grid item xs={12}>
                <FormHelperText>
                  Click on images to view fullscreen.
                </FormHelperText>
              </Grid>
              {asset?.Photos.map((photo) => (
                <Grid container spacing={2} item xs={3}>
                  <Grid item xs={12}>
                    <img
                      onClick={() => openFullscreen(photo.id)}
                      src={`/api/photos/${photo.id}?token=${searchParams.get(
                        'token'
                      )}`}
                      style={{ width: '100%', cursor: 'pointer' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {photo.label}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </CardContentWithLoader>
      </Card>
      {currentPhoto !== null && (
        <Dialog fullScreen open={currentPhoto} onClose={exitFullscreen}>
          <DialogTitle
            sx={{
              backgroundColor: '#275295',
              color: '#FFF',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={8}>
                {asset?.Photos.find((p) => p.id === currentPhoto)?.label}
              </Grid>
              <Grid sx={{ textAlign: 'right' }} item xs={4}>
                <IconButton sx={{ color: '#fff' }} onClick={exitFullscreen}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent sx={{ p: 0 }}>
            <img
              src={`/api/photos/${currentPhoto}?token=${searchParams.get(
                'token'
              )}`}
              style={{ width: '100%' }}
            />
          </DialogContent>
        </Dialog>
      )}
    </Container>
  );
}
