import * as React from 'react';

import { useEffect, useState } from 'react';

import {
  Card,
  CardHeader,
  Container,
  Button,
  Box,
  Grid,
  FormControl,
  TextField,
  CardActions,
  CardContent,
  Autocomplete,
} from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import API from '../../common/services/API';

import { useNavigate, useParams } from 'react-router-dom';
import { nullifyEmptyString } from '../../utilities';

import { Link as RouterLink } from 'react-router-dom';

export default function EditWalkthrough() {
  const { id } = useParams();

  // const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    User: null,
    name: null,
    due_date: null,
    status: null,
    Status: null,
  });
  const [users, setUsers] = useState([]);
  const [formErrors, setFormErrors] = useState({});

  const statuses = [
    { label: 'Not Started', value: 'Not Started' },
    { label: 'Collecting', value: 'Collecting' },
    { label: 'Working', value: 'Working' },
    { label: 'Completed', value: 'Completed' },
  ];

  const fetchData = async () => {
    const response = await API.get(`/walkthroughs/${id}`);
    const userResponse = await API.get('/users');
    const status = statuses.find((s) => s.value === response.data.status);

    const state = response.data;
    if (status) {
      state.Status = status;
    }

    setState(state);
    setUsers(userResponse.data);
  };

  //this is for all drop downs autocompletes
  // use a .find() on the userResponse.data and match it to the walkthroughs user
  // const walkthrough.User = user
  // set User state to that object
  // construct an object on put/post that extracts the user.id, not the whole user object

  // I dont need this here because the walkthroughs include the User object but this si what it would look like
  // const assignedUser = userResponse.data.find(
  //   (matchingUser) => matchingUser.id === walkthrough.User
  // );
  // if (assignedUser) {
  //   setState({ ...state, User: assignedUser });
  // }

  const requiredFields = {
    User: true,
    name: true,
    Status: true,
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const validate = () => {
    const errors = {};
    for (const [field, required] of Object.entries(requiredFields)) {
      if (required && !state[field]) {
        errors[field] = 'This field is required';
      }
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return false;
    }
    setFormErrors({});
    return true;
  };

  useEffect(() => fetchData(), []);

  const submit = async () => {
    if (!validate()) {
      return;
    }
    try {
      const walkthroughObject = {
        ...state,
        UserId: state.User.id,
        status: state?.Status?.value ?? 'Not Started',
      };

      const response = await API.put(
        `/walkthroughs/${id}`,
        nullifyEmptyString(walkthroughObject)
      );
      navigate(`/walkthroughs/${id}`);
    } catch (err) {
      alert('Something went wrong');
      console.error(err);
    }
  };
  return (
    <Container maxWidth="xl">
      <Card>
        <CardHeader
          title="EDIT WALKTHROUGH"
          sx={{ backgroundColor: '#275295', color: '#FFF' }}
        ></CardHeader>
        <CardContent>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    name="User"
                    label="Assigned User"
                    id="Assigned_User"
                    value={state.User}
                    onChange={(event, value) => {
                      setState({
                        ...state,
                        User: value,
                      });
                      console.log('STATE', state);
                    }}
                    onBlur={validate}
                    options={users}
                    getOptionLabel={(option) => {
                      return option.name;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={requiredFields.User}
                        error={formErrors.User}
                        helperText={formErrors.User}
                        label="Assigned User"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Name"
                    variant="outlined"
                    name="name"
                    id="Name"
                    shrink="true"
                    required={requiredFields.name}
                    value={state.name ?? ''}
                    onChange={handleFormChange}
                    error={formErrors.name}
                    helperText={formErrors.name}
                    onBlur={validate}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Due Date"
                      value={state.due_date}
                      onChange={(newValue) => {
                        setState({ ...state, due_date: newValue });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    name="Status"
                    label="Status"
                    id="Status"
                    value={state.Status}
                    onChange={(event, value) => {
                      setState({
                        ...state,
                        Status: value,
                      });
                    }}
                    onBlur={validate}
                    options={statuses}
                    getOptionLabel={(option) => {
                      return option.label;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={requiredFields.Status}
                        error={formErrors.Status}
                        helperText={formErrors.Status}
                        label="Status"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            size="medium"
            variant="text"
            component={RouterLink}
            to={`/walkthroughs/${id}`}
          >
            CANCEL
          </Button>
          <Button
            sx={{ backgroundColor: '#519801' }}
            variant="contained"
            onClick={submit}
          >
            SAVE CHANGES
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
}
