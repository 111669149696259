import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function PrivateRoute({ children, admin, ...rest }) {
  const { isLoggedIn, user } = useContext(AuthContext);
  const location = useLocation();
  if (!isLoggedIn) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
        state={{
          from: location,
        }}
      />
    );
  }

  if (admin === true && user.admin !== true) {
    // role not authorised so Navigate to home page
    return <Navigate to={{ pathname: '/' }} />;
  }

  return children;
}
