import { Grid, Alert } from '@mui/material';
import { Visibility } from '@mui/icons-material';

import AssetLocations from '../../assets/components/AssetLocations.js';
import AssetFilters from '../../assets/components/AssetFilters';
import PhotoGallery from '../../walkthroughs/components/PhotoGallery';
import AssetList from '../../assets/components/AssetList';
import AssetForm from '../../assets/components/AssetForm';

export default function SiteDetailsAssets(props) {
  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12} md={6}>
        <AssetLocations
          loading={props.loading}
          siteId={props.siteId}
          locations={props.locations}
          filters={props.filters}
          setFilters={props.setFilters}
          customFilters={props.customFilters}
          setCustomFilters={props.setCustomFilters}
          fetchData={props.fetchData}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AssetFilters
          site={props.site}
          filters={props.filters}
          setFilters={props.setFilters}
          resetFilters={props.resetFilters}
          customFilters={props.customFilters}
          setCustomFilters={props.setCustomFilters}
        />
      </Grid>
      <Grid item xs={12}>
        <AssetList
          site={props.site}
          selectAsset={props.selectAsset}
          hideAddNewAssetButton
          filters={props.filters}
          customFilters={props.customFilters}
          defaultFilters={props.defaultFilters}
          defaultCustomFilters={props.defaultCustomFilters}
          loading={props.loading}
          setLoading={props.setLoading}
          selectedAsset={props.selectedAsset}
          setSelectedAsset={props.setSelectedAsset}
          clearSelectedAsset={props.clearSelectedAsset}
          fetchData={props.fetchData}
          fetchAssets={props.fetchAssets}
          assets={props.assets}
          formData={props.formData}
          setFormData={props.setFormData}
        />
      </Grid>
      {props.selectedAsset === null && (
        <>
          <Grid item xs={12}>
            <Alert severity="info">
              <div>
                To view asset photos and asset form please click
                <Visibility
                  style={{ marginLeft: 5, marginRight: 5, marginBottom: -8 }}
                />
                on an asset above.
              </div>
            </Alert>
          </Grid>
        </>
      )}
      {props.selectedAsset !== null && (
        <>
          <Grid item xs={12} md={8}>
            <PhotoGallery
              filters={props.filters}
              selectedAsset={props.selectedAsset}
              fetchData={props.fetchData}
              fetchAssets={props.fetchAssets}
              assets={props.assets}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AssetForm
              walkthroughs={props.walkthroughs}
              site={props.site}
              selectedAsset={props.selectedAsset}
              clearSelectedAsset={props.clearSelectedAsset}
              fetchData={props.fetchData}
              fetchAssets={props.fetchAssets}
              formData={props.formData}
              setFormData={props.setFormData}
              customFormData={props.customFormData}
              setCustomFormData={props.setCustomFormData}
              locations={props.locations}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
