import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import {
  Grid,
  Menu,
  MenuItem,
  Card,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  Button,
  TextField,
  Tooltip,
  FormControl,
} from '@mui/material';

import { TreeView, TreeItem } from '@mui/lab';

import {
  Fullscreen as FullScreenIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  AddPhotoAlternate as AddPhotoIcon,
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
  EditCalendar as EditCalendarIcon,
} from '@mui/icons-material';

import * as markerjs2 from 'markerjs2';

import API from '../../common/services/API';

import CardContentWithLoader from '../../common/components/CardContentWithLoader';
import Dropzone from '../../common/components/Dropzone';

export default function PhotoGallery(props) {
  const token = localStorage.getItem('__token');
  const { id } = useParams();

  const [contextMenu, setContextMenu] = useState(null);
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const [annotatedPhoto, setAnnotatedPhoto] = useState(null);
  const [fullscreen, setFullscreen] = useState(false);
  const [markerAreaObject, setMarkerAreaObject] = useState();

  const [photoToDelete, setPhotoToDelete] = useState(null);
  const [photoToMove, setPhotoToMove] = useState(null);
  const [photoToEditLabel, setPhotoToEditLabel] = useState(null);

  const [confirmLabelOpen, setConfirmLabelOpen] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmMoveOpen, setConfirmMoveOpen] = useState(false);
  const [isAnnotationDialogueOpen, setIsAnnotationDialogueOpen] =
    useState(false);
  const [editorOpen, setEditorOpen] = useState(false);

  const [disableButton, setDisableButton] = useState(true);
  const [rightClickMenuAssets, setRightClickMenuAssets] = useState([]);
  const [movePhotoDialogueLoading, setMovePhotoDialogueLoading] =
    useState(false);

  const [markerState, setMarkerState] = useState(null);
  const [annotatedTimestamp, setAnnotatedTimestamp] = useState();

  // this returns either 'walkthroughs' or 'sites' that we use in the right-click menu
  // to make the correct api call for re-querying assets
  const location = useLocation().pathname.split('/')[1];

  const currentPhotoPath = `/api/photos/${props?.selectedAsset?.Photos?.[currentPhoto]?.id}?token=${token}`;

  useEffect(() => {
    // clear the currentPhoto to original state
    setCurrentPhoto(null);
    setAnnotatedPhoto(null);
    setMarkerState(null);
    // resetting the annotation state
    setEditorOpen(false);
    // setMarkerAreaObject(null);
    // set current photo to the last image in the array on load
    if (props?.selectedAsset?.Photos?.length > 0) {
      const lastPhoto = props.selectedAsset.Photos.length - 1;
      setCurrentPhoto(lastPhoto);
      setAnnotatedPhoto(lastPhoto);
      const parsedState = JSON.parse(
        props.selectedAsset.Photos[lastPhoto].annotated_state
      );
      setMarkerState(parsedState);
    }
  }, [props.selectedAsset]);

  useEffect(() => {
    markerAreaObject?.close(true);
    setMarkerAreaObject(null);
  }, [currentPhoto]);

  const handleContextMenu = (event, photo) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
            photo: photo,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
    setDisableButton(true);
  };

  const enterFullscreen = () => {
    setFullscreen(true);
  };

  const exitFullscreen = () => {
    setFullscreen(false);
  };

  // TODO There is room to refactor here I think. Combine the functions to accept a param of action: Move, Rename, Delete
  // as well as the very similar dialogues possibly.

  const editLabel = async () => {
    // setPhotoToEditLabel(contextMenu.photo.id);
    setPhotoToEditLabel(contextMenu.photo);
    handleLabelDialog();
    handleClose();
  };

  const handleLabelChange = async (event) => {
    const { name, value } = event.target;
    setPhotoToEditLabel({ ...photoToEditLabel, [name]: value });
  };

  const handleLabelDialog = () => {
    setConfirmLabelOpen(!confirmLabelOpen);
  };

  const confirmEditLabel = async () => {
    try {
      const response = await API.put(`/photos/${photoToEditLabel.id}`, {
        label: photoToEditLabel.label,
      });
      props.fetchAssets();
      handleLabelDialog();
    } catch (err) {
      // throws the err with data if there is then message if there is or if not, throw a generic error
      alert(err.response?.data?.message || 'There was a server error');
      console.log(err);
    }
  };

  const deletePhoto = async () => {
    setPhotoToDelete(contextMenu.photo.id);
    handleDeleteDialog();
    handleClose();
  };

  const handleDeleteDialog = () => {
    setConfirmDeleteOpen(!confirmDeleteOpen);
  };

  const confirmDeletePhoto = async () => {
    try {
      const response = await API.delete(`/photos/${photoToDelete}`);
      props.fetchAssets();
      handleDeleteDialog();
    } catch (err) {
      // throws the err with data if there is then message if there is or if not, throw a generic error
      alert(err.response?.data?.message || 'There was a server error');
      console.log(err);
    }
  };

  const movePhoto = () => {
    setPhotoToMove(contextMenu.photo);
    handleMoveDialog();
    if (confirmMoveOpen === false) {
      reQueryAssets();
    }
    handleClose();
  };

  const handlePhotoAssetIDChange = async (assetId) => {
    setPhotoToMove({ ...photoToMove, moveToId: assetId });
    setDisableButton(false);
  };

  const handleMoveDialog = () => {
    setConfirmMoveOpen(!confirmMoveOpen);
  };

  const confirmMovePhoto = async () => {
    try {
      const response = await API.put(`/photos/${photoToMove.id}/move`, {
        moveToId: photoToMove.moveToId,
      });
      props.fetchAssets();
      handleMoveDialog();
    } catch (err) {
      // throws the err with data if there is then message if there is or if not, throw a generic error
      alert(err.response?.data?.message || 'There was a server error');
      console.log(err);
    }
  };

  const handleAnnotationDialog = () => {
    setIsAnnotationDialogueOpen(!isAnnotationDialogueOpen);
  };

  const saveAnnotation = async () => {
    try {
      // annotatePhoto(markerAreaObject);
      markerAreaObject.render();
      // const response = await API.put(`/photos/${photoToMove.id}/move`, {
      //   moveToId: photoToMove.moveToId,
      // });
      // props.fetchAssets();
      // handleMoveDialog();
      // TODO save annotations here somehow
    } catch (err) {
      // throws the err with data if there is then message if there is or if not, throw a generic error
      alert(err.response?.data?.message || 'There was a server error');
      console.log(err);
    }
  };

  const handleNewFileUpload = async (uploadedFiles) => {
    const formData = new FormData();

    await uploadedFiles.forEach((file, index) => {
      // Append the files
      formData.append(`image${index}`, file, file.name);
    });

    // Append the AssetId only once
    formData.append('AssetId', props.selectedAsset.id);

    try {
      const response = await API.post('/photos', formData);
      props.fetchAssets();
    } catch (err) {
      alert('Something went wrong');
      console.error(err);
    }

    // set the current photo to the value of photos length (most recent upload)
    setCurrentPhoto(props?.selectedAsset?.Photos?.length);
  };

  const downloadPhoto = async () => {
    try {
      window.open(
        `/api/photos/${contextMenu.photo.id}/download?token=${token}`
      );
      handleClose();
    } catch (err) {
      alert(err.response?.data?.message || 'There was a server error');
      console.log(err);
    }
  };

  /**
   * [appends annotated image and markerjs state then sends it to the backend]
   *
   * @param {string} annotatedFileName [event.dataUrl that gets turned into a blob]
   * @param {object} state [image annotations that get stringified]
   *
   * @return {void}
   */
  const annotatePhoto = async (annotatedFileName, state) => {
    try {
      const blob = await (await fetch(annotatedFileName)).blob();
      const formData = new FormData();
      const stateString = JSON.stringify(state);
      formData.append('image', blob);
      formData.append('state', stateString);

      const response = await API.put(
        `/photos/${props?.selectedAsset?.Photos?.[currentPhoto]?.id}/annotate`,
        formData
      );
      props.fetchAssets();
      // set the current photo to the value of annotated photo
      setCurrentPhoto(annotatedPhoto);
      const parsedState = JSON.parse(response.data.annotated_state);
      setMarkerState(parsedState);
      setAnnotatedTimestamp(Date.now());
    } catch (err) {
      // TODO: replace this a snackbar or something
      alert('Something went wrong');
      console.error(err);
    }
  };

  /**
   * [creates the image annotation interface]
   *
   * @param   {string}  imageElement        [the image you want to annotate's html img id]
   * @param   {string}  targetElement  [img's parent(html figure) container id]
   * @param   {boolean}  popOut         [fullscreen pop out editor]
   * @param   {string}  state          [the marker state if image has been annotated]
   *
   * @return  {void}
   */
  const showMarkerArea = (
    imageElement,
    targetElement,
    popOut = false,
    state = null
  ) => {
    imageElement.src = `${currentPhotoPath}&non-annotated=true`;
    // add a callback function for when the image finishes loading then call all this
    // look up creating an image element in JS
    imageElement.onload = () => {
      const markerArea = new markerjs2.MarkerArea(imageElement);
      markerArea.renderImageType = 'image/jpeg';
      // TODO test this to see if it works
      markerArea.renderAtNaturalSize = true;
      // sets the editor target by element Id
      if (popOut === false) {
        markerArea.targetRoot = document.getElementById(targetElement);
        markerArea.addEventListener('render', (event) => {
          imageElement.src = event.dataUrl;
          annotatePhoto(event.dataUrl, event.state);
          props.fetchAssets();
          setCurrentPhoto(annotatedPhoto);
          setEditorOpen(false);
        });
      } else {
        markerArea.addEventListener('render', (event) => {
          imageElement.src = event.dataUrl;
          annotatePhoto(event.dataUrl, event.state);
          props.fetchAssets();
          setCurrentPhoto(annotatedPhoto);
          setEditorOpen(false);
        });
        markerArea.settings.displayMode = 'popup';
      }
      markerArea.show();
      if (state) {
        //set the image to the original photo
        markerArea.restoreState(state);
      }

      // make sure clicking close wasn't an accident if there are new annotations
      markerArea.addEventListener('beforeclose', (event) => {
        // TODO it is NOT EASY to compare or keep track of changes based on the nature of MarkerJS so we are just going to prompt the dialogue each time
        // console.log('event get state', event.markerArea.getState().markers);
        // console.log('state', state.markers);
        // const diff = difference(
        //   state.markers,
        //   event.markerArea.getState().markers
        // );
        // console.log({ diff });
        // create a local variable that is the state and if it has items in it from this 'session' throw this if statement
        // if (array is empty){ return }
        // if (newMarkerChanges === true) {
        //   console.log('YO THERE ARE CHANGES');
        // eslint-disable-next-line no-restricted-globals
        if (
          !window.confirm(
            'Are you sure you want to exit the edit window? Any unsaved changes will be lost.'
          )
        ) {
          // if you click cancel
          event.preventDefault();
          return;
        }
        // if you click OK
        setEditorOpen(false);
        // set the image back to the version with annotations
        imageElement.src = currentPhotoPath;
      });

      setMarkerAreaObject(markerArea);
      imageElement.onload = undefined;
    };
  };

  const preventIfEditorIsOpen = (index, photo) => {
    // the editor will remain open and not prompt to save changes if a user clicks on the same index
    // TODO explain this more
    if (currentPhoto === index) {
      return;
    }
    if (editorOpen === true) {
      if (
        !window.confirm(
          'Are you sure you want to exit the edit window? Any unsaved changes will be lost.'
        )
      ) {
        return;
      }
      //TODO this can be refactored and removed
      setCurrentPhoto(index);
      setAnnotatedPhoto(index);
      const parsedState = JSON.parse(photo.annotated_state);
      setMarkerState(parsedState);
      setEditorOpen(false);
    } else {
      setCurrentPhoto(index);
      setAnnotatedPhoto(index);
      const parsedState = JSON.parse(photo.annotated_state);
      setMarkerState(parsedState);
      setEditorOpen(false);
    }
  };

  const reQueryAssets = async () => {
    // if the deletedAt filter is toggled off we already have the correct assets to display
    if (props.filters.deletedAt === false) {
      setRightClickMenuAssets(props.assets);
      return;
    }
    // if there is data to be loaded set a loading state for the menu
    setMovePhotoDialogueLoading(true);
    const params = { deletedAt: false };
    // location value is context based sites or walkthroughs
    const assetResponse = await API.get(`/${location}/${id}/assets`, params);
    setRightClickMenuAssets(assetResponse.data);
    setMovePhotoDialogueLoading(false);
  };

  return (
    <>
      {/* Dialog for fullscreen image */}
      {currentPhoto !== null &&
        props?.selectedAsset?.Photos?.[currentPhoto] && (
          <Dialog fullScreen open={fullscreen} onClose={exitFullscreen}>
            <DialogTitle
              sx={{
                backgroundColor: '#275295',
                color: '#FFF',
                textAlign: 'right',
              }}
            >
              {/* Leaving this for now if we want to use the fullscreen edit capabilities and avoid the pop out method or have both*/}

              {/* <IconButton
                // sx={{ position: 'absolute', top: 50, right: 52 }}
                sx={{ color: '#fff' }}
                size="large"
                onClick={() =>
                  showMarkerArea(
                    document.getElementById('fullscreenSelectedPhoto'),
                    'fullscreenPhotoContainer'
                  )
                }
              >
                <EditIcon fontSize="inherit" />
              </IconButton> */}

              <IconButton sx={{ color: '#fff' }} onClick={exitFullscreen}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
              <figure id="fullscreenPhotoContainer">
                <img
                  id="fullscreenSelectedPhoto"
                  src={currentPhotoPath}
                  style={{ width: '100%' }}
                />
              </figure>
            </DialogContent>
          </Dialog>
        )}

      {/* Dialogue for changing the photo's label */}
      <Dialog
        open={confirmLabelOpen}
        fullWidth
        maxWidth="sm"
        onClose={handleLabelDialog}
      >
        <DialogTitle>Change Photo Label</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            This photo's label will be changed, are you sure?
          </DialogContentText> */}

          <TextField
            autoFocus
            margin="dense"
            label="Photo Label"
            variant="outlined"
            name="label"
            fullWidth
            type="text"
            id="Label"
            shrink="true"
            // required={requiredFields.label}
            value={photoToEditLabel?.label ?? ''}
            onChange={handleLabelChange}
            // error={formErrors.name}
            // helperText={formErrors.name}
            // onBlur={validate}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleLabelDialog();
            }}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              confirmEditLabel();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for deleting a photo */}
      <Dialog open={confirmDeleteOpen} onClose={handleDeleteDialog}>
        <DialogTitle>Delete Photo?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This photo will be deleted, are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDeleteDialog();
              setPhotoToDelete(null);
            }}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              confirmDeletePhoto();
            }}
          >
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogue for moving the photo to a new asset */}
      <Dialog open={confirmMoveOpen} onClose={handleMoveDialog}>
        <DialogTitle>Move Photo to New Asset?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To move this photo to another asset within this walkthrough, select
            the asset name below and press confirm.
          </DialogContentText>
        </DialogContent>

        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          onNodeSelect={(event, asset) => {
            handlePhotoAssetIDChange(asset);
          }}
          sx={{
            flexGrow: 1,
          }}
        >
          {movePhotoDialogueLoading ? (
            <CardContentWithLoader
              loading={movePhotoDialogueLoading}
            ></CardContentWithLoader>
          ) : (
            rightClickMenuAssets.map((asset) => (
              <TreeItem
                key={asset.id}
                nodeId={asset.id}
                label={asset.name}
              ></TreeItem>
            ))
          )}
        </TreeView>
        <DialogActions>
          <Button
            onClick={() => {
              handleMoveDialog();
              setPhotoToMove(null);
            }}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            disabled={disableButton}
            onClick={() => {
              confirmMovePhoto();
            }}
          >
            Confirm Move
          </Button>
        </DialogActions>
      </Dialog>

      {/* dialogue for moving away from annotating */}
      <Dialog
        open={isAnnotationDialogueOpen}
        fullWidth
        maxWidth="sm"
        onClose={handleAnnotationDialog}
      >
        <DialogTitle>Unsaved Annotations</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have unsaved image annotations, would you like to save them?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleAnnotationDialog();
            }}
          >
            Discard
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              saveAnnotation();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* right click menu for photo editing options */}
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={deletePhoto}>Delete</MenuItem>
        <MenuItem onClick={downloadPhoto}>Download</MenuItem>
        <MenuItem onClick={editLabel}>Edit Label</MenuItem>
        <MenuItem onClick={movePhoto}>Move Photo to New Asset</MenuItem>
        <MenuItem onClick={handleClose}>Close</MenuItem>
      </Menu>

      <Card>
        <CardContentWithLoader
          loading={props.loading}
          sx={{ backgroundColor: '#F0F1F9' }}
        >
          {/* larger display for selected image */}
          <Grid container spacing={2}>
            {currentPhoto !== null &&
              props?.selectedAsset?.Photos?.[currentPhoto || 0] && (
                <Grid item xs={12}>
                  <div
                    style={{ position: 'relative', cursor: 'pointer' }}
                    onContextMenu={(event) =>
                      handleContextMenu(
                        event,
                        props?.selectedAsset?.Photos?.[currentPhoto]
                      )
                    }
                  >
                    <IconButton
                      sx={{ position: 'absolute', top: 104, right: 0 }}
                      size="large"
                      disabled={editorOpen}
                      onClick={() => {
                        showMarkerArea(
                          document.getElementById('selectedPhoto'),
                          'photoContainer',
                          false,
                          markerState
                        );
                        setEditorOpen(true);
                      }}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>

                    <IconButton
                      sx={{ position: 'absolute', top: 52, right: 0 }}
                      size="large"
                      disabled={editorOpen}
                      onClick={() => {
                        showMarkerArea(
                          document.getElementById('selectedPhoto'),
                          'photoContainer',
                          true,
                          markerState
                        );
                      }}
                    >
                      <EditCalendarIcon fontSize="inherit" />
                    </IconButton>

                    <IconButton
                      sx={{ position: 'absolute', top: 0, right: 0 }}
                      size="large"
                      disabled={editorOpen}
                      onClick={enterFullscreen}
                    >
                      <FullScreenIcon fontSize="inherit" />
                    </IconButton>
                    <figure id="photoContainer">
                      <img
                        id="selectedPhoto"
                        src={currentPhotoPath}
                        style={{ width: '100%' }}
                      />
                      <figcaption>
                        {props?.selectedAsset?.Photos?.[currentPhoto]?.label ??
                          '-'}
                      </figcaption>
                    </figure>
                  </div>
                </Grid>
              )}
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  {/* TODO should we limit the allowed files? accept="image/png" */}

                  <Dropzone onFileAdded={handleNewFileUpload} />
                </FormControl>
              </Grid>
              {/* map over the selectedAssets photos if it has any and display them in a grid item */}
              {props?.selectedAsset?.Photos?.map((photo, index) => (
                <Grid
                  item
                  xs={3}
                  // on click of grid item set currentPhoto to the index
                  onClick={() => {
                    preventIfEditorIsOpen(index, photo);
                  }}
                >
                  <div
                    onContextMenu={(event) => handleContextMenu(event, photo)}
                  >
                    <figure>
                      <img
                        src={`/api/photos/${photo.id}?token=${token}&thumb=true&timestamp=${annotatedTimestamp}`}
                        style={{ cursor: 'pointer', maxWidth: '100%' }}
                      />
                      <Tooltip title={<h3>{photo.label ?? '-'}</h3>} arrow>
                        <figcaption
                          style={{
                            whiteSpace: 'noWrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {photo.label ?? '-'}
                        </figcaption>
                      </Tooltip>
                    </figure>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </CardContentWithLoader>
      </Card>
    </>
  );
}
