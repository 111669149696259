import {
  Card,
  CardHeader,
  Grid,
  Typography,
  CardContent,
  CircularProgress,
  FormLabel,
} from '@mui/material';

// import SiteDetailsLocations from "./SiteDetailsLocations";
import SiteDetailsWalkthroughs from './SiteDetailsWalkthroughs';
import AssetLocations from '../../assets/components/AssetLocations';
import CardContentWithLoader from '../../common/components/CardContentWithLoader';
// import { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import axios from "axios";

export default function SiteDetails(props) {
  // const { id } = useParams();
  // const [details, setDetails] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);

  // const fetchData = async () => {
  //   const response = await API.get(`/sites/${id}`);
  //   setDetails(response.data);
  //   setIsLoading(false);
  // };

  // useEffect(() => fetchData(), []);

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12}>
        <Card sx={{ backgroundColor: '#F0F1F9' }}>
          <CardHeader
            title="Details"
            sx={{ backgroundColor: '#6E7B82', color: '#FFF' }}
          ></CardHeader>
          <CardContentWithLoader loading={props.loading}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <FormLabel>Site Name</FormLabel>

                <Typography component="div">
                  {props.siteDetails.name ?? '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormLabel>Contact Name</FormLabel>

                <Typography component="div">
                  {props.siteDetails.contact_name ?? '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormLabel>Contact Email</FormLabel>

                <Typography component="div">
                  {props.siteDetails.contact_email ?? '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormLabel>Phone Number</FormLabel>
                <Typography component="div">
                  {props.siteDetails.contact_phone ?? '-'}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <FormLabel>Description</FormLabel>

                <Typography component="div">
                  {props.siteDetails.description ?? '-'}
                </Typography>
              </Grid>
            </Grid>
          </CardContentWithLoader>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <AssetLocations
          disableFilters
          siteId={props.siteId}
          fetchData={props.fetchData}
          locations={props.locations}
          loading={props.loading}
          filters={props.filters}
          setFilters={props.setFilters}
          // TODO: I dont think we need to send these custom props here
          setCustomFilters={props.setCustomFilters}
          customFilters={props.customFilters}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <SiteDetailsWalkthroughs />
      </Grid>
    </Grid>
  );
}
