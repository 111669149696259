import { Card, CardHeader, Button, Box, Grid } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Add, Edit, Visibility } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import API from '../../common/services/API';
import { useState, useEffect } from 'react';

export default function SiteDetailsWalkthroughs() {
  const { id } = useParams();
  const [walkthroughs, setWalkthroughs] = useState([]);

  const fetchData = async () => {
    const response = await API.get(`/sites/${id}/walkthroughs`);

    setWalkthroughs(response.data);
  };

  useEffect(() => fetchData(), []);

  const columns = [
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      minWidth: 100,
      getActions: (row) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          component={RouterLink}
          to={`/walkthroughs/${row.id}/edit`}
        />,
        <GridActionsCellItem
          icon={<Visibility />}
          component={RouterLink}
          to={`/walkthroughs/${row.id}`}
        />,
      ],
    },
    {
      field: 'User.name',
      headerName: 'User',
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => params.row.User.name,
    },
    {
      field: 'name',
      headerAlign: 'left',
      headerName: 'Name',
      minWidth: 100,
      disableColumnMenu: true,
      flex: 6,
      // valueGetter: (params) => console.log('PARAMS IN GRID', params),
      valueGetter: (params) => params.row.name || '-',
    },
    {
      field: 'status',
      headerAlign: 'left',
      headerName: 'Status',
      minWidth: 100,
      disableColumnMenu: true,
      flex: 6,
      valueGetter: (params) => params.row.status || '-',
    },
    {
      field: 'progress',
      headerName: 'Progress',
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        let string = params.row.progress ? `${params.row.progress}%` : '-';

        if (
          params.row.progress &&
          params.row.assetsCount !== null &&
          params.row.completedAssetsCount !== null
        ) {
          string += ` (${params.row.completedAssetsCount}/${params.row.assetsCount})`;
        }

        return string;
      },
    },
    {
      field: 'due_date',
      headerName: 'Due Date',
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
      valueFormatter: (params) => {
        if (params.value === null) {
          return;
        }
        return new Date(params.value).toLocaleDateString();
      },
    },
  ];

  return (
    <Card sx={{ backgroundColor: '#F0F1F9' }}>
      <CardHeader
        title="Walkthroughs"
        sx={{ backgroundColor: '#6E7B82', color: '#FFF' }}
        action={
          <>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Button
              variant="contained"
              startIcon={<Add />}
              sx={{ backgroundColor: '#519801' }}
              component={RouterLink}
              to={`/walkthroughs/create?SiteId=${id}`}
            >
              Add
            </Button>
          </>
        }
      ></CardHeader>
      <Grid container>
        <DataGrid
          disableSelectionOnClick
          sx={{
            '& .secondary-color-header': {
              backgroundColor: '#C4CBD6',
            },
          }}
          autoHeight
          rows={walkthroughs}
          columns={columns}
        />
      </Grid>
    </Card>
  );
}
