import axios from 'axios';
const BASE_URL = '/api';

export default {
  get: (url, params = {}) =>
    axios.get(`${BASE_URL}${url}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('__token')}`,
      },
      params: params,
    }),
  download: (url, params = {}) =>
    axios.get(`${BASE_URL}${url}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('__token')}`,
      },
      responseType: 'blob',
      params: params,
    }),
  put: (url, data) =>
    axios.put(`${BASE_URL}${url}`, data, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('__token')}`,
      },
    }),
  post: (url, data, params = {}) =>
    axios.post(`${BASE_URL}${url}`, data, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('__token')}`,
      },
      params: params,
    }),
  delete: (url) =>
    axios.delete(`${BASE_URL}${url}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('__token')}`,
      },
    }),
};
